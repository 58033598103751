

/**
 * Represents a 2D vector.
 */
export class Vector2D {
  #x = 0;
  #y = 0;

  /**
   * Creates a new Vector instance.
   * @param {number} x - The x-coordinate of the vector. Default is 0.
   * @param {number} y - The y-coordinate of the vector. Default is 0.
   */
  constructor(x = 0, y = 0) {
    this.#x = x;
    this.#y = y;
  }

  /**
   * Sets or gets the x-coordinate of the vector.
   * @param {number} [x] - The new x-coordinate value. If not provided, returns the current x-coordinate value.
   * @returns {Vector2D|number} - Returns the Vector instance if x is provided, otherwise returns the current x-coordinate value.
   */
  x(x) {
    if(x) {
      this.#x = x;
      return this;
    } else {
      return this.#x;
    }
  }

  /**
   * Sets or gets the y-coordinate of the vector.
   * @param {number} [y] - The new y-coordinate value. If not provided, returns the current y-coordinate value.
   * @returns {Vector2D|number} - Returns the Vector instance if y is provided, otherwise returns the current y-coordinate value.
   */
  y(y) {
    if(y) {
      this.#y = y;
      return this;
    }

    return this.#y;
  }

  /**
   * Sets the x and y coordinates of the vector based on the position of the mouse pointer relative to an element.
   * @param {MouseEvent} event - The mouse event object.
   * @param {HTMLElement} [element=event.currentTarget] - The HTML element to calculate the relative position to. Default is the current target of the event.
   * @returns {Vector2D} - Returns the Vector instance.
   */
  point(event, element = event.currentTarget) {
    const rect = element.getBoundingClientRect();
    this.#x = event.clientX - rect.left;
    this.#y = event.clientY - rect.top;
    return this;
  }

  /**
   * Adds the specified values to the x and y coordinates of the vector.
   * @param {number} [x=0] - The value to add to the x-coordinate. Default is 0.
   * @param {number} [y=0] - The value to add to the y-coordinate. Default is 0.
   * @returns {Vector2D} - Returns the Vector instance.
   */
  sum(x = 0, y = 0) {
    this.#x += x;
    this.#y += y;
    return this;
  }

  /**
   * Subtracts the specified values from the x and y coordinates of the vector.
   * @param {number} [x=0] - The value to subtract from the x-coordinate. Default is 0.
   * @param {number} [y=0] - The value to subtract from the y-coordinate. Default is 0.
   * @returns {Vector2D} - Returns the Vector instance.
   */
  sub(x = 0, y = 0) {
    this.#x -= x;
    this.#y -= y;
    return this;
  }

  /**
   * Multiplies the x and y coordinates of the vector by the specified values.
   * @param {number} [x=1] - The value to multiply the x-coordinate by. Default is 1.
   * @param {number} [y=1] - The value to multiply the y-coordinate by. Default is 1.
   * @returns {Vector2D} - Returns the Vector instance.
   */
  mul(x = 1, y = 1) {
    this.#x *= x;
    this.#y *= y;
    return this;
  }

  /**
   * Divides the x and y coordinates of the vector by the specified values.
   * @param {number} [x=1] - The value to divide the x-coordinate by. Default is 1.
   * @param {number} [y=1] - The value to divide the y-coordinate by. Default is 1.
   * @returns {Vector2D} - Returns the Vector instance.
   */
  div(x = 1, y = 1) {
    this.#x /= x;
    this.#y /= y;
    return this;
  }

  /**
   * Rounds down the x and y coordinates of the vector to the nearest integer.
   * @returns {Vector2D} - Returns the Vector instance.
   */
  floor() {
    this.#x = Math.floor(this.#x);
    this.#y = Math.floor(this.#y);
    return this;
  }
}

/**
 * Represents a 2D entity with size and position.
 */
export class Entity2D {
  #size = new Vector2D(0, 0);
  #position = new Vector2D(0, 0);

  /**
   * Creates a new Entity2D instance.
   * @param {Vector2D} size - The size of the entity.
   * @param {Vector2D} position - The position of the entity.
   */
  constructor(size, position) {
    this.#size = size;
    this.#position = position;
  }

  /**
   * Gets or sets the width of the entity.
   * @param {number} [width] - The width of the entity.
   * @returns {number|Entity2D} - The width of the entity if no argument is provided, or the Entity2D instance if an argument is provided.
   */
  width(width) {
    if (width) {
      this.#size.x(width);
      return this;
    } else {
      return this.#size.x();
    }
  }

  /**
   * Gets or sets the height of the entity.
   * @param {number} [height] - The height of the entity.
   * @returns {number|Entity2D} - The height of the entity if no argument is provided, or the Entity2D instance if an argument is provided.
   */
  height(height) {
    if (height) {
      this.#size.y(height);
      return this;
    } else {
      return this.#size.y();
    }
  }

  /**
   * Gets or sets the x-coordinate of the entity's position.
   * @param {number} [x] - The x-coordinate of the entity's position.
   * @returns {number|Entity2D} - The x-coordinate of the entity's position if no argument is provided, or the Entity2D instance if an argument is provided.
   */
  x(x) {
    if (x) {
      this.#position.x(x);
      return this;
    } else {
      return this.#position.x();
    }
  }

  /**
   * Gets or sets the y-coordinate of the entity's position.
   * @param {number} [y] - The y-coordinate of the entity's position.
   * @returns {number|Entity2D} - The y-coordinate of the entity's position if no argument is provided, or the Entity2D instance if an argument is provided.
   */
  y(y) {
    if (y) {
      this.#position.y(y);
      return this;
    } else {
      return this.#position.y();
    }
  }

  /**
   * Moves the entity by the specified amount.
   * @param {number} x - The amount to move the entity along the x-axis.
   * @param {number} y - The amount to move the entity along the y-axis.
   * @returns {Entity2D} - The Entity2D instance.
   */
  move(x, y) {
    this.#position.sum(x, y);
    return this;
  }

  /**
   * Resizes the entity to the specified width and height.
   * @param {number} width - The new width of the entity.
   * @param {number} height - The new height of the entity.
   * @returns {Entity2D} - The Entity2D instance.
   */
  resize(width, height) {
    this.#size.x(width);
    this.#size.y(height);
    return this;
  }

  /**
   * Floors the size and position values of the entity.
   * @returns {Entity2D} - The Entity2D instance.
   */
  floor() {
    this.#size.floor();
    this.#position.floor();
    return this;
  }
}