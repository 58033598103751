import React from "react";
import { ContexturePanel, ContextureButton, ContextureCategory, Flaticon } from "../../../../components/WePack";
import { clsx } from "../../../../modules/Utils";


const ViewPanel = React.memo(({ show, onClose, onSubmit, viewType, showPer }) => {
  const viewTypes = [
    { value: 'days', label: 'Days' },
    { value: 'weeks', label: 'Weeks' }
  ];

  const showPers = [
    { value: 'resources', label: 'Resources' },
    { value: 'projects', label: 'Project' },
  ];

  return (
    <ContexturePanel simple
      show={ show }
      onClose={ onClose }
      position={ { x: 80, y: 40 } }
    >
      <ContextureCategory label="Show per">
        { showPers.map(({ value, label }) => (
          <ContextureButton
            key={ value }
            onClick={ () => {
              onSubmit({ key: 'showPer', value });
              onClose();
            } }
          >
            <Flaticon name="check" type="rr" size={ 11 } className={ clsx("w-1", value !== showPer && 'invisible') } />
            <span>{ label }</span>
          </ContextureButton>
        )) }
      </ContextureCategory>

      <ContextureCategory label="View Type">
        { viewTypes.map(({ value, label }) => (
          <ContextureButton
            key={ value }
            onClick={ () => {
              onSubmit({ key: 'viewType', value });
              onClose();
            } }
          >
            <Flaticon name="check" type="rr" size={ 11 } className={ clsx("w-1", value !== viewType && 'invisible') } />
            <span>{ label }</span>
          </ContextureButton>
        )) }
      </ContextureCategory>

    </ContexturePanel>
  );
});

export default ViewPanel;