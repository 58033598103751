
const clsx = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const empty = (v) => {
  if (v === undefined || v === null || v === '' || v === false || v === 0 || v === '0' || v === false || (v instanceof Array && v.length === 0)) {
    return true;
  } else {
    return false;
  }
}

const switcher = (query, items = {}) => {
  if(items[query]) {
    return items[query];
  } else if(items["default"]) {
    return items["default"];
  } else {
    return false;
  }
};

const num = (val) => {
  return Number(val);
};

export {
  clsx,
  empty,
  switcher,
  num
}