import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";

const useTableData = ({ selectedTopic, setQuickAccessPanel }) => {
  const { notify } = useNotifyContext();

  const [ tableData, setTableData ] = useState(false);
  const [ showTable, setShowTable ] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setShowTable(false);
      
      setQuickAccessPanel([]);
      
      const res = await req("select-" + selectedTopic?.id);
  
      if(res.success) {
        const data = res.data;

        setQuickAccessPanel(selectedTopic?.quickAccess ?? []);

        if(showTable) {
          await setTimeout(() => {
            setTableData({ data, ...selectedTopic });
            setShowTable(true);
          }, 400);
        } else {
          setTableData({ data, ...selectedTopic });
          setShowTable(true);
        }

      } else {
        notify("Error", "Please contact the administrator", 'error');
        setTableData(false);
        setShowTable(true);
      }
    }
    
    if(selectedTopic) fetchData();
  }, [ selectedTopic ]);

  return { tableData, setTableData, showTable };
}

export default useTableData;