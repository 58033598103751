import { useEffect, useState } from "react";
import { useNavigator } from "../../../components/Navigator";
import useResources from "../hooks/useResources";
import { Flaticon, FormifyCombobox, FormifyField, FormifyInput, FormifyLabel, Modality } from "../../../../components/WePack";
import * as session from "../../../../modules/Session";
import { clsx } from "../../../../modules/Utils";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import useReasons from "../hooks/useReasons";
import { useAuth } from "../../../components/Auth";


const ResourceCalendarModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const { userData: { permissions } } = useAuth();
  const defaultData = { resourceID: "", startDate: new Date().toISOString().split('T')[ 0 ], endDate: new Date().toISOString().split('T')[ 0 ], reason: "" };

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(params[ "resources-calendar-form" ] || defaultData);
  const hasAccess = permissions.includes("SSP_AM_DATAMAN");
  const submitButtonLabel = hasAccess ? (isLoading ? "Loading..." : id ? "Edit" : "Add") : "No Access";

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: submitButtonLabel, type: "submit", styleSet: "success", disabled: isLoading || !hasAccess }
  ];

  const resources = useResources({ show });
  const reasons = useReasons({ show });

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    session.delParam("resources-calendar-form");
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const res = await req("update-resource-calendar", { ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Resource Calendar has been updated", "success");
        handleClose();
      } else {
        if(res.error === 'access-denied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    } else {
      const res = await req("insert-resource-calendar", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Resource Calendar has been added", "success");
        handleClose();
      } else {
        if(res.error === 'access-denied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    }

    setIsLoading(false);
  };

  const handleDataChange = (key, value) => {
    setData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("resources-calendar-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetchResourceCalendar = async () => {
      setIsLoading(true);

      const res = await req("select-resource-calendar-raw", { id: id });

      if (res.success) {
        setData(res.data[ 0 ]);
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchResourceCalendar();
  }, [ id ]);

  return (
    <Modality
      show={ show }
      onClose={ handleClose }
      onSubmit={ handleSubmit }
      buttons={ buttons }
      width="320px"
    >
      { /* TITLE */ }
      <div className="text-xl">
        New Resource Calendar
      </div>

      { /* RESOURCE */ }
      <FormifyField>
        <FormifyLabel>Resource</FormifyLabel>
        <FormifyCombobox
          required
          disabled={ isLoading || !hasAccess }
          value={ data.resourceID }
          options={ resources }
          onChange={ (val) => handleDataChange("resourceID", val) }
          CustomOption={ ({ label, selected }) => {
            return (
              <div className={ clsx("w-full flex justify-between px-2 py-1", selected && "bg-green-200/80") }>
                <div className="flex-1 flex">
                  <div className="rounded-full bg-green-500 p-0.5 w-6 h-6 mr-2">
                    <div className="bg-white rounded-full flex justify-center items-center text-center font-medium text-[11px] w-full h-full">
                      <span>{ (label?.[ 0 ] ?? 'U') + (label?.split(' ')[ 1 ]?.[ 0 ] ?? '') }</span>
                    </div>
                  </div>
                  <span>
                    { label }
                  </span>
                </div>
                { selected && (
                  <Flaticon name="check" size={ 14 } className="text-green-500" />
                ) }
              </div>
            );
          } }
        />
      </FormifyField>

      { /* REASON */ }
      <FormifyField>
        <FormifyLabel>Reason</FormifyLabel>
        <FormifyCombobox
          required
          disabled={ isLoading || !hasAccess }
          value={ data.reason }
          options={ reasons }
          onChange={ (val) => handleDataChange("reason", val) }
        />
      </FormifyField>

      { /* START DATE */ }
      <FormifyField>
        <FormifyLabel>Start Date</FormifyLabel>
        <FormifyInput
          required
          disabled={ isLoading || !hasAccess }
          type="date"
          max={ data.endDate }
          value={ data.startDate }
          onInput={ (val) => handleDataChange("startDate", val) }
        />
      </FormifyField>

      { /* END DATE */ }
      <FormifyField>
        <FormifyLabel>End Date</FormifyLabel>
        <FormifyInput
          required
          disabled={ isLoading || !hasAccess }
          type="date"
          min={ data.startDate }
          value={ data.endDate }
          onInput={ (val) => handleDataChange("endDate", val) }
        />
      </FormifyField>

    </Modality>
  );
};

export default ResourceCalendarModal;