import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import * as session from "../../../../modules/Session";

const useResources = ({ show }) => {
  const { notify } = useNotifyContext();

  const [ resources, setResources ] = useState(session.getStore("resources") || []);

  useEffect(() => {
    const fetchResources = async () => {
      const res = await req('select-resources');

      if (res.success) {
        const newData = res.data.map(({ id, fullname }) => ({ value: id, label: fullname }));
        session.addStore("resources", newData);
        setResources(newData);
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    };

    if (show) fetchResources();
  }, [ show ]);

  return resources;
};

export default useResources;