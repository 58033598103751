import { useEffect, useMemo, useState } from "react";
import { useNavigator } from "../../../components/Navigator";
import * as session from "../../../../modules/Session";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import { Flaticon, FormifyCombobox, FormifyField, FormifyGroup, FormifyInput, FormifyLabel, FormifyLegend, FormifySelect, FormifyTextarea, Modality } from "../../../../components/WePack";
import useJobs from "../hooks/useJobs";
import useInterventions from "../hooks/useInterventions";
import useActivities from "../hooks/useActivities";
import useInstallations from "../hooks/useInstallations";
import useResourcesExtend from "../hooks/useResourcesExtend";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { clsx } from "../../../../modules/Utils";

const ProjectModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const [ stage, setStage ] = useState(1); // [ 1, 2 ,3 ]
  const defaultData = {
    description: "",
    job: "",
    intervention: "",
    activity: "",
    installation: "",
    warranty: "Y",
    startDate: "",
    endDate: "",
    note: "",
    numberOfResourcesA: 0,
    numberOfResourcesB: 0,
    numberOfTemporaryA: 0,
    numberOfTemporaryB: 0,
    resourcesA: [],
    resourcesB: [],
    temporaryA: [],
    temporaryB: []
  };

  const [ closeConfirm, setCloseConfirm ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(params[ "projects-form" ] || defaultData);

  const jobs = useJobs({ show });
  const interventions = useInterventions({ show });
  const activities = useActivities({ show });
  const installations = useInstallations({ show });
  const resources = useResourcesExtend({ show, from: data.startDate, to: data.endDate, id });

  const buttons = (
    stage === 1 ? [
      { name: "Cancel", disabled: isLoading, onClick: () => setCloseConfirm(true) },
      { name: "Next", styleSet: "success", disabled: isLoading, type: "submit" }
    ] : [
      { name: "Back", disabled: isLoading, onClick: () => setStage(1) },
      { name: isLoading ? ("Loading...") : (id ? "Update" : "Add"), type: "submit", styleSet: "success", disabled: isLoading }
    ]
  );

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    setStage(1);
    session.delParam("projects-form");
    setCloseConfirm(false);
    onClose();
  };

  const resourceAFiltered = resources?.filter(({ id, skillmatrix, officeRaw }) => {
    return !data.resourcesA?.includes(id)
      && skillmatrix?.filter(({ activity }) => activity === data.activity).length > 0
      && officeRaw !== 'TM';
  }).map(({ id, fullname, skillmatrix, tasks }) => ({
    id,
    label: fullname,
    tasks: tasks,
    hashTags: [
      ...activities.filter(({ value }) => skillmatrix?.some(({ activity }) => (
        activity === value
      ))).map(({ label, value }) => {
        const name = label.split(' ').map((item) => item[ 0 ].toUpperCase() + (item.length > 1 ? item.slice(1) : '')).join('');
        const skill = skillmatrix.find(({ activity }) => activity === value);
        const rate = '[' + skill.expectedLevel + '/' + skill.evaluatedLevel + ']';
        return name + rate;
      })
    ]
  }));

  const resourceASelected = resources?.filter(({ id }) => data.resourcesA?.includes(id)).map(({ id, fullname, skillmatrix, tasks }) => ({
    id,
    label: fullname,
    tasks: tasks,
    hashTags: [
      ...activities.filter(({ value }) => skillmatrix?.some(({ activity }) => (
        activity === value
      ))).map(({ label, value }) => {
        const name = label.split(' ').map((item) => item[ 0 ].toUpperCase() + (item.length > 1 ? item.slice(1) : '')).join('');
        const skill = skillmatrix.find(({ activity }) => activity === value);
        const rate = '[' + skill.expectedLevel + '/' + skill.evaluatedLevel + ']';
        return name + rate;
      })
    ]
  }));

  const resourceBFiltered = resources?.filter(({ id, skillmatrix, officeRaw }) => {
    return !data.resourcesB?.includes(id)
      && (skillmatrix?.length > 0 ? skillmatrix?.filter(({ activity }) => activity !== data.activity).length > 0 : true)
      && (officeRaw !== 'TM');
  }).map(({ id, fullname, skillmatrix, tasks }) => ({
    id,
    label: fullname,
    tasks: tasks,
    hashTags: [
      ...activities.filter(({ value }) => skillmatrix?.some(({ activity }) => (
        activity === value
      ))).map(({ label, value }) => {
        const name = label.split(' ').map((item) => item[ 0 ].toUpperCase() + (item.length > 1 ? item.slice(1) : '')).join('');
        const skill = skillmatrix.find(({ activity }) => activity === value);
        const rate = '[' + skill.expectedLevel + '/' + skill.evaluatedLevel + ']';
        return name + rate;
      })
    ]
  }));

  const resourceBSelected = resources?.filter(({ id }) => data.resourcesB?.includes(id)).map(({ id, fullname, skillmatrix, tasks }) => ({
    id,
    label: fullname,
    tasks: tasks,
    hashTags: [
      ...activities.filter(({ value }) => skillmatrix?.some(({ activity }) => (
        activity === value
      ))).map(({ label, value }) => {
        const name = label.split(' ').map((item) => item[ 0 ].toUpperCase() + (item.length > 1 ? item.slice(1) : '')).join('');
        const skill = skillmatrix.find(({ activity }) => activity === value);
        const rate = '[' + skill.expectedLevel + '/' + skill.evaluatedLevel + ']';
        return name + rate;
      })
    ]
  }));

  const temporaryAFiltered = resources?.filter(({ id, skillmatrix, officeRaw }) => {
    return !data.temporaryA?.includes(id)
      && skillmatrix?.filter(({ activity }) => activity === data.activity).length > 0
      && officeRaw === 'TM';
  }).map(({ id, fullname, skillmatrix, tasks }) => ({
    id,
    label: fullname,
    tasks: tasks,
    hashTags: [
      ...activities.filter(({ value }) => skillmatrix?.some(({ activity }) => (
        activity === value
      ))).map(({ label, value }) => {
        const name = label.split(' ').map((item) => item[ 0 ].toUpperCase() + (item.length > 1 ? item.slice(1) : '')).join('');
        const skill = skillmatrix.find(({ activity }) => activity === value);
        const rate = '[' + skill.expectedLevel + '/' + skill.evaluatedLevel + ']';
        return name + rate;
      })
    ]
  }));

  const temporaryASelected = resources?.filter(({ id }) => data.temporaryA?.includes(id)).map(({ id, fullname, skillmatrix, tasks }) => ({
    id,
    label: fullname,
    tasks: tasks,
    hashTags: [
      ...activities.filter(({ value }) => skillmatrix?.some(({ activity }) => (
        activity === value
      ))).map(({ label, value }) => {
        const name = label.split(' ').map((item) => item[ 0 ].toUpperCase() + (item.length > 1 ? item.slice(1) : '')).join('');
        const skill = skillmatrix.find(({ activity }) => activity === value);
        const rate = '[' + skill.expectedLevel + '/' + skill.evaluatedLevel + ']';
        return name + rate;
      })
    ]
  }));

  const temporaryBFiltered = resources?.filter(({ id, skillmatrix, officeRaw }) => {
    return !data.temporaryB?.includes(id)
      && (skillmatrix?.length > 0 ? skillmatrix?.filter(({ activity }) => activity !== data.activity).length > 0 : true)
      && officeRaw === 'TM';
  }).map(({ id, fullname, skillmatrix, tasks }) => ({
    id,
    label: fullname,
    tasks: tasks,
    hashTags: [
      ...activities.filter(({ value }) => skillmatrix?.some(({ activity }) => (
        activity === value
      ))).map(({ label, value }) => {
        const name = label.split(' ').map((item) => item[ 0 ].toUpperCase() + (item.length > 1 ? item.slice(1) : '')).join('');
        const skill = skillmatrix.find(({ activity }) => activity === value);
        const rate = '[' + skill.expectedLevel + '/' + skill.evaluatedLevel + ']';
        return name + rate;
      })
    ]
  }));

  const temporaryBSelected = resources?.filter(({ id }) => data.temporaryB?.includes(id)).map(({ id, fullname, skillmatrix, tasks }) => ({
    id,
    label: fullname,
    tasks: tasks,
    hashTags: [
      ...activities.filter(({ value }) => skillmatrix?.some(({ activity }) => (
        activity === value
      ))).map(({ label, value }) => {
        const name = label.split(' ').map((item) => item[ 0 ].toUpperCase() + (item.length > 1 ? item.slice(1) : '')).join('');
        const skill = skillmatrix.find(({ activity }) => activity === value);
        const rate = '[' + skill.expectedLevel + '/' + skill.evaluatedLevel + ']';
        return name + rate;
      })
    ]
  }));

  const handleDataChange = (key, value) => {
    setData(curr => {
      if (key === "numberOfResourcesA" && value < curr.resourcesA.length) {
        const newData = { ...curr, [ key ]: value, resourcesA: [] };
        session.addParam("projects-form", newData);
        return newData;
      } else if (key === "numberOfResourcesB" && value < curr.resourcesB.length) {
        const newData = { ...curr, [ key ]: value, resourcesB: [] };
        session.addParam("projects-form", newData);
        return newData;
      } else if (key === "numberOfTemporaryA" && value < curr.temporaryA.length) {
        const newData = { ...curr, [ key ]: value, temporaryA: [] };
        session.addParam("projects-form", newData);
        return newData;
      } else if (key === "numberOfTemporaryB" && value < curr.temporaryB.length) {
        const newData = { ...curr, [ key ]: value, temporaryB: [] };
        session.addParam("projects-form", newData);
        return newData;
      } else {
        const newData = { ...curr, [ key ]: value };
        session.addParam("projects-form", newData);
        return newData;
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (stage === 1) {
      setStage(2);
      return;
    }

    setIsLoading(true);

    if (id) {
      const res = await req("update-project", { id, ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Project has been updated", "success");
        handleClose();
      } else if(res.error === 'same-description') {
        notify("Error", "Project with the same description already exists", 'error');
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    } else {
      const res = await req("insert-project", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Project has been added", "success");
        handleClose();
      } else if(res.error === 'same-description') {
        notify("Error", "Project with the same description already exists", 'error');

      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const res = await req("select-projects-raw", { id });

      if (res.success) {
        const res2 = await req("select-project-resources", { id });

        if (res2.success) {
          setData({
            ...res.data[ 0 ],
            resourcesA: res2.data.resourcesA,
            resourcesB: res2.data.resourcesB,
            temporaryA: res2.data.temporaryA,
            temporaryB: res2.data.temporaryB
          });
        } else {
          notify("Error", "Please contact the administrator", 'error');
          handleClose();
        }
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchData();
  }, [ id ]);

  return <Modality show={ show } onClose={ () => setCloseConfirm(true) } onSubmit={ handleSubmit } width="920px" buttons={ buttons }>
    <FormifyLegend className="text-xl">{ id ? "Edit Project" : "New Project" } <span className="font-semibold">{ data.description || '...' }</span></FormifyLegend>

    <Modality
      show={ closeConfirm }
      label="Warning!"
      icon={ { name: "triangle-warning", type: "br", className: "text-red-600" } }
      onClose={ () => setCloseConfirm(false) }
      buttons={ [
        { name: "No", onClick: () => setCloseConfirm(false) },
        { name: "Yes", styleSet: 'error', onClick: handleClose }
      ] }
    >
      Are you sure you want to close this form? All unsaved changes will be lost.
    </Modality>

    { stage === 1 ? <>
      <FormifyGroup>
        { /* NAME */ }
        <FormifyField disabled={ isLoading }>
          <FormifyLabel>Name</FormifyLabel>
          <FormifyInput required
            autoComplete="off"
            value={ data.description }
            onInput={ (val) => handleDataChange("description", val) }
          />
        </FormifyField>

        { /* JOB */ }
        <FormifyField disabled={ isLoading }>
          <FormifyLabel>Job</FormifyLabel>
          <FormifyCombobox required
            autoComplete="off"
            value={ data.job }
            onChange={ (val) => handleDataChange("job", val) }
            options={ jobs }
          />
        </FormifyField>

        { /* INTERVENTION TYPE */ }
        <FormifyField disabled={ isLoading }>
          <FormifyLabel>Intervention Type</FormifyLabel>
          <FormifyCombobox required
            autoComplete="off"
            value={ data.intervention }
            onChange={ (val) => handleDataChange("intervention", val) }
            options={ interventions }
          />
        </FormifyField>
      </FormifyGroup>

      <FormifyGroup>
        { /* ACTIVITY TYPE */ }
        <FormifyField disabled={ isLoading }>
          <FormifyLabel>Activity Type</FormifyLabel>
          <FormifyCombobox required
            autoComplete="off"
            value={ data.activity }
            onChange={ (val) => handleDataChange("activity", val) }
            options={ activities }
          />
        </FormifyField>

        { /* INSTALLATION */ }
        <FormifyField disabled={ isLoading }>
          <FormifyLabel>Installation</FormifyLabel>
          <FormifyCombobox required
            autoComplete="off"
            value={ data.installation }
            onChange={ (val) => handleDataChange("installation", val) }
            options={ installations }
          />
        </FormifyField>

        { /* WARRANTY */ }
        <FormifyField disabled={ isLoading }>
          <FormifyLabel>Warranty</FormifyLabel>
          <FormifySelect required
            value={ data.warranty ?? 'Y' }
            onChange={ (val) => handleDataChange("warranty", val) }
            options={ [ { value: "Y", label: "Yes" }, { value: "N", label: "No" } ] }
          />
        </FormifyField>
      </FormifyGroup>

      { /* ESTIMATED START */ }
      <FormifyGroup>
        <FormifyField disabled={ isLoading }>
          <FormifyLabel>Estimated Start</FormifyLabel>
          <FormifyInput required
            type="date"
            max={ data.endDate }
            autoComplete="off"
            value={ data.startDate }
            onInput={ (val) => handleDataChange("startDate", val) }
          />
        </FormifyField>

        { /* ESTIMATED END */ }
        <FormifyField disabled={ !data.startDate || isLoading }>
          <FormifyLabel>Estimated End</FormifyLabel>
          <FormifyInput required
            type="date"
            min={ data.startDate }
            autoComplete="off"
            value={ data.endDate }
            onInput={ (val) => handleDataChange("endDate", val) }
          />
        </FormifyField>
      </FormifyGroup>

      { /* Note */ }
      <FormifyField>
        <FormifyLabel>Note</FormifyLabel>
        <FormifyTextarea
          disabled={ isLoading }
          value={ data.note }
          onInput={ (val) => handleDataChange("note", val) }
          rows={ 4 }
        />
      </FormifyField>
    </> : <>
      <FormifyGroup addClasses="mb-3">
        { /* Number Of Resources A */ }
        <FormifyField>
          <FormifyLabel>Resources A</FormifyLabel>
          <FormifyInput required
            type="number"
            min={ 0 }
            step={ 1 }
            value={ data.numberOfResourcesA }
            onInput={ (val) => handleDataChange("numberOfResourcesA", val) }
          />
        </FormifyField>

        { /* Number Of Resources B */ }
        <FormifyField>
          <FormifyLabel>Resources B</FormifyLabel>
          <FormifyInput required
            type="number"
            min={ 0 }
            step={ 1 }
            value={ data.numberOfResourcesB }
            onInput={ (val) => handleDataChange("numberOfResourcesB", val) }
          />
        </FormifyField>

        { /* Number Of Temporary A */ }
        <FormifyField>
          <FormifyLabel>Temporary A</FormifyLabel>
          <FormifyInput required
            type="number"
            min={ 0 }
            step={ 1 }
            value={ data.numberOfTemporaryA }
            onInput={ (val) => handleDataChange("numberOfTemporaryA", val) }
          />
        </FormifyField>

        { /* Number Of Temporary B */ }
        <FormifyField>
          <FormifyLabel>Temporary B</FormifyLabel>
          <FormifyInput required
            type="number"
            min={ 0 }
            step={ 1 }
            value={ data.numberOfTemporaryB }
            onInput={ (val) => handleDataChange("numberOfTemporaryB", val) }
          />
        </FormifyField>
      </FormifyGroup>

      <BookSelector
        from={ data.startDate }
        to={ data.endDate }
        categories={ [
          {
            id: 'resourcesA',
            label: 'Resources A (' + data.resourcesA?.length + '/' + data.numberOfResourcesA + ')',
            data: resourceAFiltered,
            selected: resourceASelected,
            disabled: data.numberOfResourcesA <= 0,
            correct: data.numberOfResourcesA <= data.resourcesA?.length,
            onSelect: (id) => {
              if (data.numberOfResourcesA <= data.resourcesA.length) return notify("Error", "Number of Resources A exceeded", 'error');
              handleDataChange("resourcesA", [ ...data.resourcesA, id ]);
            },
            onUnselect: (id) => { handleDataChange("resourcesA", data.resourcesA.filter(item => item !== id)); }
          },
          {
            id: 'resourcesB',
            label: 'Resources B (' + data.resourcesB?.length + '/' + data.numberOfResourcesB + ')',
            data: resourceBFiltered,
            selected: resourceBSelected,
            disabled: data.numberOfResourcesB <= 0,
            correct: data.numberOfResourcesB <= data.resourcesB?.length,
            onSelect: (id) => {
              if (data.numberOfResourcesB <= data.resourcesB.length) return notify("Error", "Number of Resources B exceeded", 'error');
              handleDataChange("resourcesB", [ ...data.resourcesB, id ]);
            },
            onUnselect: (id) => { handleDataChange("resourcesB", data.resourcesB.filter(item => item !== id)); }
          },
          {
            id: 'temporaryA',
            label: 'Temporary A (' + data.temporaryA?.length + '/' + data.numberOfTemporaryA + ')',
            data: temporaryAFiltered,
            selected: temporaryASelected,
            disabled: data.numberOfTemporaryA <= 0,
            correct: data.numberOfTemporaryA <= data.temporaryA?.length,
            onSelect: (id) => {
              if (data.numberOfTemporaryA <= data.temporaryA.length) return notify("Error", "Number of Temporary A exceeded", 'error');
              handleDataChange("temporaryA", [ ...data.temporaryA, id ]);
            },
            onUnselect: (id) => { handleDataChange("temporaryA", data.temporaryA.filter(item => item !== id)); }
          },
          {
            id: 'temporaryB',
            label: 'Temporary B (' + data.temporaryB?.length + '/' + data.numberOfTemporaryB + ')',
            data: temporaryBFiltered,
            selected: temporaryBSelected,
            disabled: data.numberOfTemporaryB <= 0,
            correct: data.numberOfTemporaryB <= data.temporaryB?.length,
            onSelect: (id) => {
              if (data.numberOfTemporaryB <= data.temporaryB.length) return notify("Error", "Number of Temporary B exceeded", 'error');
              handleDataChange("temporaryB", [ ...data.temporaryB, id ]);
            },
            onUnselect: (id) => { handleDataChange("temporaryB", data.temporaryB.filter(item => item !== id)); }
          }
        ] }
      />
    </> }
  </Modality>;
};

const BookSelector = ({ categories, from, to }) => {
  const [ queryToBeAssigned, setQueryToBeAssigned ] = useState("");
  const [ queryAssigned, setQueryAssigned ] = useState("");

  return <TabGroup>
    <TabList className="w-full flex rounded-md">
      { categories.map(tab => (
        <Tab
          key={ tab.id }
          disabled={ tab.disabled }
          className={ clsx(
            "data-[selected]:bg-white disabled:text-gray-500 data-[selected]:font-semibold py-1 border-b",
            "border-l last:border-r border-t outline-none bg-gray-100 data-[selected]:border-b-white",
            "data-[selected]:border-t flex-1 first:rounded-tl-md last:rounded-tr-md cursor-pointer",
            tab.correct ? "text-green-400" : ""
          ) }
        >
          { tab.label }
        </Tab>
      )) }
    </TabList>
    <TabPanels className="h-[400px] border-x border-b rounded-b-md">
      { categories.map((tab) => (
        <TabPanel key={ tab.id } className="w-full flex h-full rounded-b-md">
          <div className="flex-1 rounded-bl-md border-r border-gray-300 overflow-y-auto divide-y divide-gray-200">
            <div className="flex px-2 py-1 items-center">
              <div className="flex-1">Resource to be assigned</div>
              <FormifyInput
                type="text"
                addClasses="w-[140px] py-0"
                placeholder="Search..."
                value={ queryToBeAssigned }
                onInput={ (val) => setQueryToBeAssigned(val) }
              />
            </div>
            { tab.data?.map(({ label, id, hashTags, tasks }) => {
              const splitQ = queryToBeAssigned.toLowerCase().split(' ');

              const validated = splitQ.every(q => {
                const lowerLabel = label.toLowerCase();
                const mappedHashtags = hashTags?.map(tag => tag.toLowerCase()) ?? [];

                if (q.startsWith('#') && mappedHashtags?.length > 0) {
                  const hashtagsValidated = mappedHashtags?.some(tag => tag.includes(q.slice(1)));
                  const labelValidated = lowerLabel.includes(q);

                  return hashtagsValidated || labelValidated;
                } else {
                  return lowerLabel.includes(q);
                }
              });


              if (queryToBeAssigned === "" || validated) {
                const filteredHashTags = hashTags?.filter((tag) => {
                  return queryToBeAssigned.toLowerCase().split(' ').some(q => q.startsWith('#') && tag.toLowerCase().includes(q.slice(1)))
                  || queryToBeAssigned === ""
                  || !queryToBeAssigned.includes("#");
                }).map((tag) => (
                  <span key={ tag }>
                    #<span className={ "text-gray-500" }>{ tag }</span>
                  </span>
                ));

                return (
                  <div
                    key={ id }
                    className="flex items-center space-x-2 hover:bg-gray-200 cursor-pointer px-2 last:border-b py-0.5"
                    onClick={ () => tab.onSelect(id) }
                  >
                    <div className="[&:hover_.tasks]:flex relative">
                      { tasks?.length > 0
                        && tasks?.filter((task) => from >= task.start && to <= task.end).length > 0 ? (
                          <Flaticon name="calendar-xmark" type="rr" size={ 18 } className="text-red-600" />
                      ) : tasks?.length > 0 ? (
                        <Flaticon name="calendar-clock" type="rr" size={ 18 } className="text-orange-600" />
                      ) : (
                        <Flaticon name="calendar-check" type="rr" size={ 18 } className="text-green-600" />
                      ) }

                      { tasks?.length > 0 && (
                        <div className="hidden min-w-[220px] tasks bg-white flex-col z-[60] absolute top-full left-0 p-2 rounded-md shadow-[0_0_4px_0_#00000050]">
                          <div className="text-xs text-gray-500 divide-y divide-gray-300">
                            { tasks?.map(({ label, start, end }) => (
                              <div className="w-full flex flex-col py-1 last:pb-0 first:pt-0">
                                <span className="text-nowrap text-gray-800">{ label }</span>
                                <span className="text-nowrap text-black font-medium" key={ start + end }>
                                  { start.split('-').reverse().join('/') } - { end.split('-').reverse().join('/') }
                                </span>
                              </div>
                            )) }
                          </div>
                        </div>
                      ) }
                    </div>
                    <div className="flex flex-col flex-1 relative">
                      <div className="p-0">{ label }</div>
                      <div className="[&:hover_.hashtags-hidden]:visible">
                        <div className="hashtags flex space-x-1 text-xs text-gray-800 text-nowrap flex-wrap overflow-y-hidden max-h-8">
                          { filteredHashTags ?? "" }
                        </div>
                        <div
                          className={ clsx(
                            "hashtags-hidden invisible pointer-events-none p-1 box-content bg-white rounded-md shadow-[0_0_4px_0_#00000080]",
                            "z-50 absolute top-5 -left-1 flex-wrap flex space-x-1 text-xs text-gray-800",
                            hashTags?.length <= 0 && "hidden"
                          )}
                        >
                          { filteredHashTags ?? "" }
                        </div>
                      </div>
                    </div>
                  </div>

                );
              }
            }) }
          </div>
          <div className=" flex-1 rounded-br-md overflow-y-auto divide-y divide-gray-200">
            <div className="flex px-2 py-1 items-center">
              <div className="flex-1">Assigned Resources</div>
              <FormifyInput
                type="text"
                addClasses="w-[140px] py-0"
                placeholder="Search..."
                value={ queryAssigned }
                onInput={ (val) => setQueryAssigned(val) }
              />
            </div>
            { tab.selected?.map(({ label, id, hashTags, tasks }) => {
              const splitQ = queryAssigned.toLowerCase().split(' ');

              const validated = queryAssigned ? splitQ.every(q => {
                const lowerLabel = label.toLowerCase();
                const mappedHashtags = hashTags?.map(tag => tag.toLowerCase()) ?? [];

                if (q.startsWith('#') && mappedHashtags?.length > 0) {
                  const hashtagsValidated = mappedHashtags?.some(tag => tag.includes(q.slice(1)));
                  const labelValidated = lowerLabel.includes(q);

                  return hashtagsValidated || labelValidated;
                } else {
                  return lowerLabel.includes(q);
                }
              }) : true;

              if (queryAssigned === "" || validated) {
                const filteredHashTags = hashTags?.filter((tag) => {
                  return queryAssigned.toLowerCase().split(' ').some(q => q.startsWith('#') && tag.toLowerCase().includes(q.slice(1)))
                  || queryAssigned === ""
                  || !queryAssigned.includes("#");
                }).map(tag => (
                  <span key={ tag }>
                    #<span className="text-gray-500">{ tag }</span>
                  </span>
                ));

                return (
                  <div
                    key={ id }
                    className="flex items-center space-x-2 hover:bg-gray-200 cursor-pointer px-2 last:border-b py-0.5 "
                    onClick={ () => tab.onUnselect(id) }
                  >
                    <div>
                      { tasks?.length > 0
                        && tasks?.filter((task) => from >= task.start && to <= task.end).length > 0 ? (
                          <Flaticon name="calendar-xmark" type="rr" size={ 18 } className="text-red-600" />
                      ) : tasks?.length > 0 ? (
                        <Flaticon name="calendar-clock" type="rr" size={ 18 } className="text-orange-600" />
                      ) : (
                        <Flaticon name="calendar-check" type="rr" size={ 18 } className="text-green-600" />
                      ) }
                    </div>
                    <div className="flex flex-col flex-1 relative">
                      <div className="p-0">{ label }</div>
                      <div className="[&:hover_.hashtags-hidden]:visible">
                        <div className="hashtags flex space-x-1 flex-wrap text-xs text-gray-800 overflow-y-hidden max-h-8">
                          { filteredHashTags ?? "" }
                        </div>
                        <div
                          className={ clsx(
                            "hashtags-hidden invisible pointer-events-none p-1 box-content bg-white rounded-md shadow-[0_0_4px_0_#00000050]",
                            "z-50 absolute top-5 -left-1 flex-wrap flex space-x-1 text-xs text-gray-800",
                            hashTags?.length <= 0 && "hidden"
                          )}
                        >
                          { filteredHashTags ?? "" }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            }) }
          </div>
        </TabPanel>
      )) }
    </TabPanels>
  </TabGroup>;
};

export default ProjectModal;