import { Transition } from "@headlessui/react";
import { clsx, switcher } from "../../modules/Utils";
import { Fragment, createContext, useContext, useEffect, useRef, useState } from "react";
import { Flaticon } from "../../components/WePack";

const Notify = () => {
  const { notifyData, closeNotify } = useNotifyContext();

  return <Transition
    as={ Fragment }
    show={ notifyData.show }
    enter="transition ease-out duration-300 "
    enterFrom="transform opacity-0 -translate-y-5"
    enterTo="transform opacity-100 translate-y-0"
    leave="transition ease-in duration-300"
    leaveFrom="transform opacity-100  translate-y-0"
    leaveTo="transform opacity-0 -translate-y-5"
  >
    <div
      onClick={ (e) => {
        notifyData.onClick && notifyData.onClick();
        closeNotify();
      } }
      className={ clsx(
        "fixed flex items-stretch rounded-lg shadow-[0_0_2px_0_#00000020] w-[400px] right-2 top-11 z-[55] cursor-pointer p-2",
        switcher(notifyData.type, {
          'error': 'bg-red-500',
          'warn': 'bg-yellow-500',
          'info': 'bg-blue-500',
          'default': 'bg-green-500'
        })
      ) }
    >
      <div className="self-stretch flex justify-center items-start pr-2">
        { switcher(notifyData.type, {
          'error': <Flaticon name="octagon-xmark" type="rr" className="text-white p-1" size={ 24 } />,
          'warn': <Flaticon name="triangle-warning" type="rr" className="text-white p-1" size={ 24 } />,
          'info': <Flaticon name="info" type="rr" className="text-white p-1" size={ 24 } />,
          'default': <Flaticon name="check-circle" type="rr" className="text-white p-1" size={ 24 } />
        }) }
      </div>
      <div className="flex-1 flex flex-col justify-center">
        <div className="font-medium text-white">
          { notifyData.label }
        </div>
        <div className="text-white">
          { notifyData.message }
        </div>
      </div>
    </div>
  </Transition>;
};

const NotifyContext = createContext();

const NotifyProvider = ({ children }) => {
  const [ notifyData, setNotifyData ] = useState({ show: false, label: '', message: '', type: 'info', onClick: null });
  const notifyTimeout = useRef(null);

  const notify = (label, message, type) => {
    if (notifyData.show) {
      clearTimeout(notifyTimeout.current);
      setNotifyData(curr => ({ ...curr, show: false, onClick: null }));

      setTimeout(() => {
        setNotifyData({ label, message, type, show: true });

        notifyTimeout.current = setTimeout(() => {
          setNotifyData(curr => ({ ...curr, show: false, onClick: null }));
        }, 3000);
      }, 350);
    } else {
      setNotifyData({ label, message, type, show: true });
      notifyTimeout.current = setTimeout(() => {
        setNotifyData(curr => ({ ...curr, show: false, onClick: null }));
      }, 3000);
    }
  };

  const closeNotify = () => {
    clearTimeout(notifyTimeout.current);
    setNotifyData(curr => ({ ...curr, show: false }));
  };

  useEffect(() => {
    return () => clearTimeout(notifyTimeout.current);
  }, []);

  const value = {
    notify,
    notifyData,
    closeNotify,
    setNotifyData
  };

  return (
    <NotifyContext.Provider value={ value }>
      { children }
    </NotifyContext.Provider>
  );
};

const useNotifyContext = () => useContext(NotifyContext);

export { useNotifyContext, NotifyProvider };
export default Notify;