import React from "react";
import { useContextMenu } from "../../../components/ContextMenu";

const Table = React.memo(({ data, columns, columnsIDs, context }) => {
  return <table className="border-collapse w-full max-w-full">
    <TableHeader columns={ columns } />
    <TableBody data={ data } columns={ columns } columnsIDs={ columnsIDs } context={ context } />
  </table>;
});

const TableHeader = ({ columns }) => {
  return <thead>
    <tr className="sticky top-0 shadow-[0_0_2px_#00000030] bg-white font-medium">
      { columns.map((column, i) => {
        return <TableHeaderCell key={ 'TableHEaderCell-' + i } column={ column } />;
      }) }
    </tr>
  </thead>;
};

const TableHeaderCell = ({ column }) => {
  return (
    <th className="px-2 border border-gray-300 text-start relative min-w-[40px]">
      <span>{ column }</span>
    </th>
  );
};

const TableBody = ({ data, columns, columnsIDs, context }) => {
  return (
    <tbody>
      { data?.length > 0 ? (
        data.map((row, i) => (
          <TableRow
            key={ 'TableRow-' + i }
            row={ row }
            columnsIDs={ columnsIDs }
            context={ context }
          />
        ))
      ) : (
        <tr>
          <td className="border-r border-b border-gray-300" colSpan={ columns.length }>
            <div className="flex justify-center items-center h-20">
              <h1 className="text-xl">Missing data...</h1>
            </div>
          </td>
        </tr>
      ) }
    </tbody>
  );
};

const TableRow = ({ row, columnsIDs, context }) => {
  const { setContextMenu } = useContextMenu();

  return <tr className="hover:bg-gray-100 bg-white">
    { columnsIDs.map((columnID, i) => {
      return <td key={ 'td-' + i } className="p-0 border border-gray-300">
        <div
          className="w-full h-full px-1"
          onContextMenu={ (e) => {
            if (!context) return;
            e.preventDefault();
            setContextMenu({
              context,
              position: { x: e.clientX, y: e.clientY },
              data: { row, columnID }
            });
          } }
        >
          { row[ columnID ] }
        </div>
      </td>;
    }) }
  </tr>;
};

export default Table;