import { useEffect, useState } from "react";


const useCalendar = () => {
  const [ calendar, setCalendar ] = useState({ holidays: [] });

  useEffect(() => {
    const fetchCalendar = async () => {
      const holidays = Array.from({ length: 2000 }, (_, i) => {
        // Weekends of 2024
        const date = new Date(2022, 0, i);
        const dayOfWeek = date.getDay();
        
        if(dayOfWeek === 1 || dayOfWeek === 0) {
          return date.toISOString().split('T')[0];
        }
      });

      setCalendar({ holidays });
    }

    fetchCalendar();
  }, []);

  return { calendar };
}

export default useCalendar;