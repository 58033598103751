import { createContext, useContext, useState } from "react";
import { Contexture, ContextureButton, ContexturePanel, ContextureSwitch, Flaticon, Modality } from "../../components/WePack";
import { Menu, MenuButton, MenuItem, MenuItems, Transition, TransitionChild } from "@headlessui/react";
import config from "../../config/config.json";
import { clsx } from "../../modules/Utils";
import * as session from "../../modules/Session";
import { useAuth } from "./Auth";
import { useTitleBarTracker } from "./TitleBarTracker";

import SignOutIcon from "../../assets/icons/sign-out.png";
import SSPLogo from "../../assets/ssp-logo-light.png";

/* NAVBAR */
const Navbar = ({ nav, usernav }) => {
  const { isOverlayVisible } = useTitleBarTracker();
  const { handleSignOut } = useAuth();

  const [ showSignOutModal, setShowSignOutModal ] = useState(false);

  return (
    <div className={ clsx(
      "flex justify-between items-center select-none h-full w-[env(titlebar-area-width,100%)] duration-150 pl-1",
      isOverlayVisible && "pr-4"
    ) } >
      <SignOutModal
        show={ showSignOutModal }
        onClose={ () => setShowSignOutModal(false) }
        onSignOut={ () => {
          handleSignOut();
          setShowSignOutModal(false);
        } }
      />

      <NavbarLogo />

      <QuickAccessPanel />

      <div className="flex space-x-1 items-center">
        <AppsMenu nav={ nav } />

        <UserMenu usernav={ usernav } onSignOut={ () => setShowSignOutModal(true) } />
      </div>

    </div>
  );
};

/* SIGN OUT MODAL */
const SignOutModal = ({ show, onClose, onSignOut }) => {
  return (
    <Modality
      show={ show }
      onClose={ onClose }
      label="Sign Out"
      image={ SignOutIcon }
      buttons={ [
        { name: "Cancel", onClick: onClose },
        { name: "Sign Out", onClick: onSignOut, styleSet: 'error' }
      ] }
      className="text-sm"
    >
      You want to sign out?
    </Modality>
  );
};

/* NAVBAR LOGO */
const NavbarLogo = () => {
  return (
    <div className={ clsx(
      "overflow-hidden min-w-[calc(env(titlebar-area-height,33px)*0.7*2.6)] w-[calc(env(titlebar-area-height,33px)*0.7*2.6)]",
      "h-[calc(env(titlebar-area-height,33px)*0.7)] [-webkit-app-region:drag]"
    ) }>
      <img src={ SSPLogo } alt="Trillium Logo" className="w-full h-full select-none pointer-events-none" />
    </div>
  );
};

/* QUICK ACCESS PANEL */
const QuickAccessPanel = () => {
  const { showQuickAccessPanel, quickAccessPanel } = useNavigator();
  const { isOverlayVisible } = useTitleBarTracker();

  return (
    <div className="flex-1 px-2 flex items-center">
      <Transition
        show={ showQuickAccessPanel }
        as="div"
        className="flex space-x-2 overflow-hidden"
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-x-10"
        enterTo="opacity-100 translate-x-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        { quickAccessPanel.map(({ name, onClick, icon, type, onInput }) => (
          type === 'button' ? (
            <button
              key={ name }
              className="flex items-center justify-center px-1.5 space-x-1 hover:bg-blue-600 rounded-md text-white text-nowrap"
              onClick={ onClick }
            >
              <Flaticon name={ icon.name } type={ icon.type } size={ "calc(env(titlebar-area-height,33px)*0.4)" } />
              <span className="ml-2 text-[calc(env(titlebar-area-height,33px)*0.4)]">{ name }</span>
            </button>
          ) : type === 'search' && (
            <div className="relative flex" key={ name }>
              <Flaticon
                className="absolute top-1/2 -translate-y-1/2 right-1.5 text-gray-400"
                name={ icon.name }
                type={ icon.type }
                size={ 16 }
              />
              <input
                type="text"
                className="pl-2 pr-7 p-0 rounded-md border border-transparent focus:border-blue-300 focus:outline-none focus:ring-0"
                placeholder={ name }
                onInput={ onInput }
              />
            </div>
          )
        )) }
      </Transition>

      { isOverlayVisible && (
        <div className="flex-1 w-full [-webkit-app-region:drag] h-[env(titlebar-area-height,33px)] top-0 left-0 z-50" />
      ) }
    </div>
  );
};

/* APPS MENU */
const AppsMenu = ({ nav }) => {
  const { active, go, setShowQuickAccessPanel } = useNavigator();

  return (
    <Contexture>
      { ({ show, setShow }) => {
        return (
          <>
            <ContextureSwitch
              onClick={ () => setShow(true) }
              className={ clsx(
                "flex justify-center items-center rounded-full hover:bg-[#007DFF] h-fit w-fit duration-100 z-50",
                "w-[calc(env(titlebar-area-height,33px)*0.9)] h-[calc(env(titlebar-area-height,33px)*0.9)]"
              ) }
            >
              <Flaticon name="grid" type="sr" className="text-white" size="calc(env(titlebar-area-height,33px)*0.5)" />
            </ContextureSwitch>

            <ContexturePanel simple
              show={ show }
              onClose={ () => setShow(false) }
              className={ clsx(
                "p-2 top-10 rounded-xl shadow-lg border-gray-200 bg-white border",
                "grid grid-cols-2 gap-2 left-[calc(env(titlebar-area-width,100%)-195px)]"
              ) }
              enter="transition ease-out duration-150 origin-top-right"
              enterFrom="scale-0 -translate-y-6 -translate-x-12 opacity-0"
              enterTo="scale-100 translate-y-0 translate-x-0 opacity-100"
              leave="transition ease-in duration-150 origin-top-right"
              leaveFrom="scale-100 translate-y-0 translate-x-0 opacity-100"
              leaveTo="scale-0 -translate-y-6 -translate-x-12 opacity-0"
            >
              { nav.map(({ id, name, icon, image }) => (
                <ContextureButton
                  key={ id }
                  as="button"
                  className="flex flex-col w-20 justify-start rounded-lg items-center p-1.5 hover:bg-gray-100 space-y-1"
                  onClick={ () => {
                    if (id === active) return;
                    setShowQuickAccessPanel(false);
                    go(id);
                    setShow(false);
                  } }
                >
                  { image ? (
                    <img src={ image } className="w-[38px]" />
                  ) : (
                    <Flaticon name={ icon.name } type={ icon.type } size={ 36 } />
                  ) }

                  <div className="text-xs font-medium">{ name }</div>
                </ContextureButton>
              )) }
            </ContexturePanel>
          </>
        );
      } }
    </Contexture>
  );
};

/* USER MENU */
const UserMenu = ({ usernav, onSignOut }) => {
  const { userData: { fullname, initials, username } } = useAuth();
  const softwareVersion = config.softwareVersion;
  return (
    <Contexture>
      { ({ show, setShow }) => {
        return (
          <>
            <ContextureSwitch
              onClick={ () => setShow(true) }
              className={ clsx(
                "rounded-full bg-green-500 p-0.5 duration-100 w-[calc(env(titlebar-area-height,33px)*0.8)]",
                "h-[calc(env(titlebar-area-height,33px)*0.8)]"
              ) }
            >
              <div className={ clsx(
                "bg-white rounded-full flex justify-center items-center text-center font-medium text-sm w-full h-full",
                "text-[calc(env(titlebar-area-height,33px)*0.4)]"
              ) }>
                { initials }
              </div>
            </ContextureSwitch>

            <ContexturePanel simple
              show={ show }
              onClose={ () => setShow(false) }
              enter="transition ease-out duration-150 origin-top-right"
              enterFrom="scale-0 -translate-y-6 -translate-x-4 opacity-0"
              enterTo="scale-100 translate-y-0 translate-x-0 opacity-100"
              leave="transition ease-in duration-200 origin-top-right"
              leaveFrom="scale-100 translate-y-0 translate-x-0 opacity-100"
              leaveTo="scale-0 -translate-y-6 -translate-x-4 opacity-0"
              className={ clsx(
                "p-2 w-[180px] z-50 absolute top-10 rounded-3xl shadow-lg border-gray-200 bg-white border",
                "left-[calc(env(titlebar-area-width,100%)-193px)] flex flex-col justify-center items-center"
              ) }
            >
              <div className="absolute top-0 rounded-t-3xl w-full h-8 bg-[#0050a3]" />

              <div className="flex justify-center items-center rounded-full bg-green-500 p-0.5 duration-100 w-12 h-12 z-10" >
                <div className="bg-white rounded-full w-full h-full flex justify-center items-center text-center font-medium text-xl shadow-lg">
                  { initials }
                </div>
              </div>

              <div className="flex flex-col w-full justify-center items-center mt-2 text-center">
                <div className="bg-gray-200 px-1 rounded-lg">{ username }</div>
                <div>{ fullname }</div>
                <div className="text-gray-400 text-xs">Software Version { softwareVersion }</div>
              </div>

              <div className="flex flex-col w-full border-t pt-1 mt-2">
                { usernav.map(({ id, name, className, onClick }) => (
                  <button
                    key={ id }
                    className="flex w-full justify-center rounded-lg last:rounded-b-2xl items-center p-1.5 duration-150 hover:bg-gray-100 space-x-2 px-2"
                    onClick={ () => {
                      if (id === 'logout') {
                        onSignOut();
                        setShow(false);
                      } else {
                        onClick();
                        setShow(false);
                      }
                    } }
                  >
                    <div className={ className }>{ name }</div>
                  </button>
                )) }
              </div>
            </ContexturePanel>
          </>
        );
      } }
    </Contexture>
  );
};

const NavigatorContext = createContext();

const NavigatorProvider = ({ children }) => {
  const [ active, setActive ] = useState(session.getActive() || 'dashboard');
  const [ params, setParams ] = useState(session.getParams() || {});
  const [ showQuickAccessPanel, setShowQuickAccessPanel ] = useState(false);
  const [ quickAccessPanel, setQuickAccessPanel ] = useState([]);

  const go = (_active, _params = {}) => {
    if (_active !== active) {
      session.setSession({ active: _active, params: _params });
      setActive(_active);
      setParams(_params);
    }
  };

  const value = {
    active,
    params,
    go,
    showQuickAccessPanel,
    setShowQuickAccessPanel,
    quickAccessPanel,
    setQuickAccessPanel: (items) => {
      if (showQuickAccessPanel) {
        setShowQuickAccessPanel(false);
      }

      setTimeout(() => {
        setQuickAccessPanel(items);
        setShowQuickAccessPanel(true);
      }, 350);
    }
  };

  return <NavigatorContext.Provider value={ value }>
    { children }
  </NavigatorContext.Provider>;
};

const useNavigator = () => useContext(NavigatorContext);

export default Navbar;
export { useNavigator, NavigatorProvider };