import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';
import App from './app';
import { NotifyProvider } from './app/components/Notify';
import { NavigatorProvider } from './app/components/Navigator';
import { AuthProvider } from './app/components/Auth';
import { TitleBarTrackerProvider } from './app/components/TitleBarTracker';
import { ContextMenuProvider } from './app/components/ContextMenu';
import { LoadingProvider } from './app/components/LoadingScreen';

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );

root.render(
  <LoadingProvider>
    <NotifyProvider>
      <TitleBarTrackerProvider>
        <AuthProvider>
          <NavigatorProvider>
            <ContextMenuProvider>
              <App />
            </ContextMenuProvider>
          </NavigatorProvider>
        </AuthProvider>
      </TitleBarTrackerProvider>
    </NotifyProvider>
  </LoadingProvider>
);

reportWebVitals();
