import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import * as session from "../../../../modules/Session";

const useActivities = ({ show }) => {
  const [ activities, setActivities ] = useState(session.getStore("activities") || []);
  const { notify } = useNotifyContext();

  useEffect(() => {
    const fetchActivities = async () => {
      const res = await req("select-activities");

      if (res.success) {
        const newDate = res.data.map(item => ({ value: item.id, label: item.description }));
        session.addStore("activities", newDate);
        setActivities(newDate);
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    };

    if (show) fetchActivities();
  }, [ show ]);

  return activities;
};

export default useActivities;