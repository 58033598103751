
import { Button, Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Description, Dialog, DialogPanel, DialogTitle, Field, Fieldset, Input, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Radio, RadioGroup, Textarea, Transition, TransitionChild } from "@headlessui/react";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";

import LoadingAnimation from "../assets/loading-screen.svg";
import { switcher, clsx } from "../modules/Utils";

const Flaticon = ({ name, type, size, className, style }) => {
  return (
    <i
      style={ { ...style, fontSize: size } }
      className={ clsx(className, "flex justify-center items-center fi", "fi" + (type ? "-" + type : "") + "-" + (name ?? 'question')) }
    />
  );
};

const Contexture = ({ children }) => {
  const [ show, setShow ] = useState(false);

  return (
    <div className="relative">
      { children({ show, setShow }) }
    </div>
  );
};

const ContextureSwitch = ({ onClick, className, children }) => {
  return (
    <button
      onClick={ onClick }
      className={ className }
    >
      { children }
    </button>
  );
};

const ContexturePanel = ({ show, onClose, position, children, addClasses, className, simple,
  enter, enterFrom, enterTo, leave, leaveFrom, leaveTo, outClose }) => {
  const DefaultClassName = clsx(
    "w-full border focus:outline-none border-gray-300 bg-white rounded-lg",
    "shadow-lg flex flex-col items-stretch justify-center p-1 min-w-36",
    addClasses
  );

  const firstEnter = useRef(false);

  const isFirstOpen = useRef(true);

  const handleMouseEnter = () => {
    if (!firstEnter.current) {
      firstEnter.current = true;
    }
  };

  useEffect(() => {
    if (!show) {
      isFirstOpen.current = true;
      return;
    }

    const handleClickOutside = (e) => {
      if (e.target.closest('.contexture') || e.target.classList.contains('contexture')) return;
      if (isFirstOpen.current) return;

      isFirstOpen.current = true;
      firstEnter.current = false;

      onClose();
    };

    window.addEventListener('click', handleClickOutside);

    setTimeout(() => isFirstOpen.current = false, 50);
    return () => window.removeEventListener('click', handleClickOutside);
  }, [ show ]);

  const handleMouseLeave = () => {
    if (firstEnter.current && outClose) {
      firstEnter.current = false;
      isFirstOpen.current = true;
      onClose();
    }
  };

  if (simple) {
    if (position && position.x && position.y) {
      return (
        <Transition
          as="div"
          show={ show }
          onMouseEnter={ handleMouseEnter }
          onMouseLeave={ handleMouseLeave }
          className={ clsx(
            "fixed z-50 text-nowrap outline-none contexture",
            (position.y > window.innerHeight * 0.75) && "-translate-y-full",
            (position.x > (window.innerWidth * 0.75)) && "-translate-x-full",
          ) }
          style={ { left: position.x, top: position.y } }
        >
          { position.y > window.innerHeight * 0.75 && position.x > window.innerWidth * 0.75 ? (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-150 ease-out"
              enterFrom="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-150 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : position.y > window.innerHeight * 0.75 ? (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-150 ease-out"
              enterFrom="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-150 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : position.x > window.innerWidth * 0.75 ? (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-150 ease-out"
              enterFrom="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-150 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-150 ease-out"
              enterFrom="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-150 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) }
        </Transition>
      );
    } else {
      return (
        <Transition
          as="div"
          show={ show }
          className={ clsx(className ?? DefaultClassName, "fixed z-50 outline-none contexture") }
          enter={ enter ?? "transition transform duration-150 ease-out" }
          enterFrom={ enterFrom ?? "opacity-0 scale-0" }
          enterTo={ enterTo ?? "opacity-100 scale-100" }
          leave={ leave ?? "transition transform duration-150 ease-in" }
          leaveFrom={ leaveFrom ?? "opacity-100 scale-100" }
          leaveTo={ leaveTo ?? "opacity-0 scale-0" }
          onMouseEnter={ handleMouseEnter }
          onMouseLeave={ handleMouseLeave }
        >
          { children }
        </Transition>
      );
    }
  } else {
    return (
      <Transition
        as={ Dialog }
        onClose={ onClose }
        show={ show }
        className={ clsx(
          "fixed z-50 text-nowrap outline-none",
          (position.y > window.innerHeight * 0.75) && "-translate-y-full",
          (position.x > (window.innerWidth * 0.75)) && "-translate-x-full",
        ) }
        style={ { left: position.x, top: position.y } }
      >
        { (position.y > window.innerHeight * 0.75 && position.x > window.innerWidth * 0.75) ? (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-150 ease-out"
            enterFrom="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-150 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : position.y > window.innerHeight * 0.75 ? (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-150 ease-out"
            enterFrom="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-150 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : position.x > window.innerWidth * 0.75 ? (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-150 ease-out"
            enterFrom="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-150 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-150 ease-out"
            enterFrom="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-150 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) }
      </Transition>
    );
  }
};

const ContextureCategory = ({ children, addClasses, label, ...props }) => {
  return (
    <div
      { ...props }
      className={ clsx(
        "border-t mt-2 pt-2.5 w-full flex flex-col items-stretch justify-center relative",
        "",
        addClasses
      ) }
    >
      { label && (
        <div className="text-[11px] absolute bg-white top-0 left-[1px] px-2 -translate-y-1/2 text-gray-600">{ label }</div>
      ) }
      { children }
    </div>
  );
};

const ContextureButton = ({ children, addClasses, className, onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <button
      className={ className ?? clsx(
        "flex justify-start rounded-md items-center px-3 py-0.5 hover:bg-blue-600",
        "space-x-2 text-sm text-nowrap hover:text-white",
        addClasses
      ) }
      onClick={ onClick }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
    >
      { children }
    </button>
  );
};

const Popcraft = ({ show, children, className, posX, posY }) => {

  return (
    <Transition
      show={ show }
      className="fixed z-50"
      style={ { top: posY, left: posX } }
    >
      <TransitionChild
        as={ Fragment }
        enter="transition ease-out duration-75 origin-top"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75 origin-top"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className={ className ?? "min-w-[220px] bg-white rounded-sm shadow-lg border border-gray-300" } >
          { children }
        </div>
      </TransitionChild>
    </Transition>
  );
};

const Formify = ({ children, onSubmit, ...props }) => {
  return <Fieldset
    { ...props }
    onSubmit={ onSubmit }
    as="form"
  >
    { children }
  </Fieldset>;
};

const FormifyField = ({ children, className, addClasses, ...props }) => {
  return <Field
    { ...props }
    className={ clsx(className ?? "flex flex-col w-full", addClasses) }
  >
    { children }
  </Field>;
};

const FormifyGroup = ({ children, className, addClasses, ...props }) => {
  return <Field
    { ...props }
    className={ clsx(className ?? "flex w-full space-x-2", addClasses) }
  >
    { children }
  </Field>;
};

const FormifyLegend = ({ children, className, addClasses, ...props }) => {
  return <h1
    { ...props }
    className={ clsx(className ?? "mb-2 text-xl", addClasses) }
  >
    { children }
  </h1>;
};

const FormifyLabel = ({ children, className, addClasses, ...props }) => {
  return <Label
    className={ clsx(className ?? "pl-1", addClasses) }
    { ...props }
  >
    { children }
  </Label>;
};

const FormifyDescription = ({ children, className, addClasses, ...props }) => {
  return <Description
    className={ clsx(className ?? "pl-1", addClasses) }
    { ...props }
  >
    { children }
  </Description>;
};

const FormifyInput = ({ onInput, required, onCheck, onChange, className, addClasses, max, min, value, defaultValue, type, step, autoComplete, ...props }) => {
  return <Input
    { ...props }
    autoComplete={ autoComplete }
    required={ required }
    max={ max }
    min={ min }
    step={ step }
    value={ value }
    defaultValue={ defaultValue }
    type={ type ?? "text" }
    className={ clsx(
      className ?? "border border-gray-300 rounded-md px-1.5 py-0.5 focus:ring-0  focus:border-blue-600 "
      + "invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium",
      addClasses
    ) }
    onInput={ (e) => {
      e.preventDefault();
      onInput && onInput(e.target.value);
    } }
    onChange={ (e) => {
      e.preventDefault();
      onChange && onChange(e.target.value);
      onCheck && onCheck(e.target.checked);
    } }
  />;
};

const FormifyTextarea = ({ onInput, value, className, addClasses, disabled, maxLength, required, rows, ...props }) => {
  return <Textarea
    { ...props }
    required={ required }
    disabled={ disabled }
    maxLength={ maxLength }
    value={ value }
    rows={ rows }
    className={ clsx(
      className ?? "border border-gray-300 rounded-md px-1.5 py-0.5 focus:ring-0  focus:border-blue-600 resize-none "
      + "invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium",
      addClasses
    ) }
    onInput={ (e) => onInput(e.target.value) }
  />;
};

const FormifyPalette = ({ onChange, value, className, addClasses, disabled, required, ...props }) => {
  return <div className="flex justify-between">
    <div className="font-medium">{ value }</div>
    <Input
      { ...props }
      disabled={ disabled }
      required={ required }
      type="color"
      value={ value }
      className={ clsx(
        className ?? "border-0 bg-transparent w-12 h-8 appearance-none cursor-pointer",
        addClasses
      ) }
      onChange={ (e) => onChange(e.target.value) }
    />
  </div>;
};

const FormifySelect = ({ onChange, className, addClasses, options, value }) => {
  return <Listbox value={ value } onChange={ (val) => onChange(val) } >
    <ListboxButton
      className={ clsx(
        className ?? "border flex border-gray-300 rounded-md px-1.5 py-0.5 focus:ring-0  focus:border-blue-600 w-full "
        + "invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium",
        addClasses
      ) }
    >
      { options.find(option => option.value === value)?.label }
    </ListboxButton>
    <ListboxOptions
      anchor="bottom"
      transition
      className="z-50 border w-[var(--button-width)] bg-white rounded-md divide-y empty:invisible shadow-[0_0_4px_0_#00000020]"
    >
      { options.length > 0 && options?.map((option, index) => (
        <ListboxOption
          key={ option.value }
          value={ option.value }
          className="data-[focus]:bg-gray-200 cursor-pointer flex"
        >
          <div className="w-full h-full flex mx-2 py-1 space-x-2 hover:space-x-0 overflow-hidden items-center hover:items-start hover:flex-col duration-75">
            <div className="flex space-x-2 duration-75 text-nowrap">
              <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-600", value === option.value ? "visible" : "invisible") } />
              <span className="flex-1">{ option.label }</span>
            </div>
          </div>
        </ListboxOption>
      )) }
    </ListboxOptions>
  </Listbox>;
};

const FormifyCombobox = ({ onChange, className, addClasses, options, disabled, value, autoComplete, required, CustomOption }) => {
  const [ query, setQuery ] = useState('');

  const filteredOptions = useMemo(() => {
    return query !== '' ? (
      options.filter(option => {
        const splitQ = query.toLowerCase().split(' ');

        const validated = splitQ.every(q => {
          const label = option.label.toLowerCase();
          const hashtags = option.hashTags?.map(tag => tag.toLowerCase()) ?? [];

          if (q.startsWith('#') && hashtags?.length > 0) {
            const hashtagsValidated = hashtags?.some(tag => tag.includes(q.slice(1)));
            const labelValidated = label.includes(q);

            return hashtagsValidated || labelValidated;
          } else {

            return label.includes(q);
          }
        });

        return validated;
      })
    ) : (
      options
    );
  }, [ query, options ]);


  return <div className="relative w-full">
    <Combobox immediate value={ value } onChange={ onChange } onClose={ () => setQuery('') }>
      <div className="relative">
        <ComboboxInput
          required={ required }
          disabled={ disabled }
          autoComplete={ autoComplete }
          className={ clsx(
            className ?? "border border-gray-300 rounded-md pl-1.5 pr-8 py-0.5 focus:ring-0  focus:border-blue-600 w-full "
            + "invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium",
            addClasses
          ) }
          displayValue={ (value) => options.find(option => option.value === value)?.label }
          onChange={ (event) => setQuery(event.target.value) }
        />

        <ComboboxButton className="group absolute inset-y-0 right-0 px-3.5">
          <Flaticon name="angle-down" type="bs" size={ 12 } className="text-gray-500 group-data-[hover]:fill-white" />
        </ComboboxButton>
      </div>
      <ComboboxOptions
        anchor="bottom start"
        className="z-50 border w-[var(--input-width)] bg-white rounded-md divide-y empty:invisible shadow-[0_0_4px_0_#00000020]"
      >
        { filteredOptions.map((option, i) => (
          <ComboboxOption
            key={ i }
            value={ option.value }
            className="data-[focus]:bg-gray-200 cursor-pointer flex"
          >
            { ({ focus, selected }) => <>
              { CustomOption ? (
                <CustomOption
                  focus={ focus }
                  selected={ selected }
                  label={ option.label }
                  value={ option.value }
                />
              ) : (
                <div
                  className={ clsx(
                    "w-full h-full flex mx-2 py-1 space-x-2 hover:space-x-0 overflow-hidden items-center",
                    "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap"
                  ) }
                >
                  <div className="flex space-x-2 label  duration-75 text-nowrap">
                    <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-600", value === option.value ? "visible" : "invisible") } />
                    <span className="flex-1">{ option.label }</span>
                  </div>
                  <span className="hashtags flex overflow-hidden space-x-1 data-[selected]:font-bold duration-75 ">
                    { option.hashTags?.map((tag, i) => (<>
                      <span key={ i } className="text-xs text-gray-800 rounded-md text-ellipsis">#<span className="text-gray-500">{ tag }</span></span>
                    </>)) }
                  </span>
                </div>
              ) }
            </> }
          </ComboboxOption>
        )) }
      </ComboboxOptions>
    </Combobox>
  </div>;
};

const FormifyLevel = ({ onChange, className, addClasses, step, from, to, disabled, value, defaultValue }) => {
  return (
    <RadioGroup
      disabled={ disabled }
      value={ value }
      onChange={ onChange }
    >
      <div className="flex justify-between space-x-1">
        { Array.from({ length: to - from + 1 }, (_, i) => i + from).map((level, i) => (
          <Radio
            key={ i }
            value={ level }
            className={ clsx(
              "flex flex-1 cursor-pointer justify-center items-center w-6 h-6 rounded-md",
              "font-semibold bg-white text-gray-800 shadow-[0_0_2px_0_#00000060]",
              "data-[checked]:bg-blue-600 data-[checked]:text-white",
              "data-[disabled]:bg-gray-200 data-[disabled]:text-gray-800"
            ) }
          >
            { level }
          </Radio>
        )) }
      </div>
    </RadioGroup>
  );
};

const FormifySubmit = ({ children, className, addClasses, ...props }) => {
  return <button
    { ...props }
    className={ clsx(
      className ?? "border disabled:bg-green-300 disabled:border-green-300 disabled:text-green-100 disabled:cursor-not-allowed "
      + "px-4 py-0.5 duration-75 bg-green-500 hover:bg-green-400 border-green-400 hover:border-green-300 text-white "
      + "shadow-md rounded-md",
      addClasses
    ) }
  >
    { children }
  </button>;
};

const FormifyCancel = ({ children, className, addClasses, ...props }) => {
  return <button
    { ...props }
    className={ clsx(
      className ?? "border disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed "
      + "px-4 py-0.5 duration-75 bg-gray-50 hover:bg-white border-green-300 hover:border-green-200 "
      + "shadow-md rounded-md",
      addClasses
    ) }
  >
    { children }
  </button>;
};

const ReactioAnchors = {
  "ss": "translate(0, 0)",
  "sc": "translate(-50%, 0)",
  "se": "translate(-100%, 0)",
  "cs": "translate(0, -50%)",
  "cc": "translate(-50%, -50%)",
  "ce": "translate(-100%, -50%)",
  "es": "translate(0, -100%)",
  "ec": "translate(-50%, -100%)",
  "ee": "translate(-100%, -100%)",
  1: "translate(0, 0)",
  2: "translate(-50%, 0)",
  3: "translate(-100%, 0)",
  4: "translate(0, -50%)",
  5: "translate(-50%, -50%)",
  6: "translate(-100%, -50%)",
  7: "translate(0, -100%)",
  8: "translate(-50%, -100%)",
  9: "translate(-100%, -100%)"
};

const Rectio = ({ w, h, x, y, r, className, a, style }) => {
  return (
    <div
      className={ className }
      style={ { ...style, width: w, height: h, left: x, top: y, transform: "rotate(" + (r ?? "0deg") + ") " + ReactioAnchors[ a ] ?? "translate(0, 0)" } }
    />
  );
};

const RectioSquare = ({ s, x, y, r, a, className }) => {
  return (
    <Rectio
      className={ clsx("absolute", className ?? "bg-gray-300") }
      w={ s }
      h={ s }
      x={ x }
      y={ y }
      r={ r }
      a={ a }
    />
  );
};

const RectioCircle = ({ size, x, y, r, a, className }) => {
  return (
    <Rectio
      className={ clsx("absolute rounded-full", className ?? "bg-gray-300") }
      w={ size }
      h={ size }
      x={ x }
      y={ y }
      r={ r }
      a={ a }
    />
  );
};

const RectioRectangle = ({ w, h, x, y, r, a, className }) => {
  return (
    <Rectio
      className={ clsx("absolute", className ?? "bg-gray-300") }
      w={ w }
      h={ h }
      x={ x }
      y={ y }
      r={ r }
      a={ a }
    />
  );
};

const RectioTriangle = ({ size, x, y, r, a, className }) => {
  return (
    <Rectio
      className={ clsx("absolute", className ?? "bg-gray-300") }
      style={ { borderLeft: size + "px solid transparent", borderRight: size + "px solid transparent", borderBottom: size + "px solid currentColor" } }
      w={ 0 }
      h={ 0 }
      x={ x }
      y={ y }
      r={ r }
      a={ a }
    />
  );
};

const Loady = ({ show = true, children, color, size, className, label }) => {
  return show ? (
    <div className={ "flex flex-col justify-center items-center " + className }>
      { label && <div className="text-2xl font-medium my-4" style={ { color } }>{ label }</div> }
      <img src={ LoadingAnimation } alt="Loading..." style={ { width: size, height: size, stroke: color } } />
    </div>
  ) : (
    children
  );
};

const Modality = ({ show, label, children, buttons, onClose, className, image, icon, width, onSubmit, preventClose }) => {
  return <Transition show={ show }>
    <Dialog as="div" className="z-50 focus:outline-none" onClose={ () => !preventClose && onClose() }>
      <TransitionChild
        as={ Fragment }
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black/50 backdrop-blur-sm" />
      </TransitionChild>

      <div className="fixed z-50 inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 scale">
          <TransitionChild
            as={ Fragment }
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-110"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-100"
          >
            <DialogPanel style={ { width: width ?? "448px", maxWidth: width ?? "448px" } } className="rounded-lg max-h-[90%] overflow-y-auto shadow-lg bg-white px-3 py-2 border border-gray-300">

              <form onSubmit={ onSubmit ?? ((e) => e.preventDefault()) }>
                <div className="flex justify-center items-center space-x-4">
                  { icon ? (
                    <Flaticon name={ icon.name } type={ icon.type } className={ icon.className } size={ 42 } />
                  ) : image && (
                    <img src={ image } className="w-[48px]" />
                  ) }
                  <div className="flex flex-col flex-1">
                    <DialogTitle as="div" className="font-medium">
                      { label }
                    </DialogTitle>
                    <div className={ className }>
                      { children }
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex justify-end space-x-2">
                  { buttons.map(({ name, onClick, type = "button", styleSet = "default", disabled = false }, i) => {
                    return <Button
                      key={ i }
                      type={ type }
                      disabled={ disabled }
                      onClick={ onClick }
                      className={ clsx(
                        "inline-flex items-center gap-2 rounded-md px-4 text-sm/6 font-semibold focus:outline-none",
                        switcher(styleSet, {
                          'error': "border border-red-400 bg-red-600 hover:bg-red-500 text-white disabled:bg-red-300 disabled:border-red-300 disabled:text-red-100 disabled:cursor-not-allowed",
                          "info": "border border-gray-300 bg-blue-600 hover:bg-blue-500 text-white disabled:bg-blue-300 disabled:border-blue-300 disabled:text-blue-100 disabled:cursor-not-allowed",
                          "success": "border border-gray-300 bg-green-600 hover:bg-green-500 text-white disabled:bg-green-300 disabled:border-green-300 disabled:text-green-100 disabled:cursor-not-allowed",
                          "default": "border border-gray-300 bg-gray-50 text-black hover:text-gray-600 disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-100 disabled:cursor-not-allowed"
                        })
                      ) }
                    >
                      { name }
                    </Button>;
                  }) }
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </Transition>;
};

export {
  Contexture,
  ContextureSwitch,
  ContexturePanel,
  ContextureCategory,
  ContextureButton,
  Popcraft,
  Formify,
  FormifyDescription,
  FormifyCombobox,
  FormifyField,
  FormifyInput,
  FormifyLabel,
  FormifyPalette,
  FormifySelect,
  FormifyGroup,
  FormifySubmit,
  FormifyCancel,
  FormifyTextarea,
  FormifyLevel,
  FormifyLegend,
  Rectio,
  RectioCircle,
  RectioRectangle,
  RectioSquare,
  RectioTriangle,
  Flaticon,
  Loady,
  Modality
};