import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { FormifyCombobox, FormifyField, FormifyInput, FormifyLabel, Modality } from "../../../../components/WePack";
import * as session from "../../../../modules/Session";
import useOffices from "../hooks/useOffices";
import { useNotifyContext } from "../../../components/Notify";
import useTravelAvailabilities from "../hooks/useTravelAvailabilities";
import { useNavigator } from "../../../components/Navigator";
import useResponsables from "../hooks/useResponsables";
import { useAuth } from "../../../components/Auth";

const ResourceModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const { userData: { permissions } } = useAuth();
  const defaultData = { fullname: "", office: "", responsable: "", travelAvailability: "" };

  const [ data, setData ] = useState(params[ "resources-form" ] || defaultData);
  const [ isLoading, setIsLoading ] = useState(false);
  const hasAccess = permissions.includes("SSP_AM_DATAMAN");

  const submitButtonLabel = hasAccess ? (
    isLoading ? (
      "Loading..."
    ) : (
      id ? "Edit" : "Add"
    )
  ) : (
    "No Access"
  );

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: submitButtonLabel, type: "submit", styleSet: "success", disabled: isLoading || !hasAccess }
  ];

  const offices = useOffices({ show });
  const travelAvailabilities = useTravelAvailabilities({ show });
  const responsables = useResponsables({ show });

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    session.delParam("resources-form");
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const res = await req("update-resource", { ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Resource has been updated", "success");
        handleClose();
      } else {
        if (res.error === 'access-denied') {
          notify("Error", "You don't have permission to do this action", 'error');
        } else if (res.error === 'already-exists') {
          notify("Warning", "Resource name already exists", 'warn');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    } else {
      const res = await req("insert-resource", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Resource has been inserted", "success");
        handleClose();
      } else {
        if (res.error === 'access-denied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else if (res.error === 'already-exists') {
          notify("Warning", "Resource name already exists", 'warn');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    }

    setIsLoading(false);
  };

  const handleDataChange = (key, value) => {
    setData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("resources-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetchResource = async () => {
      setIsLoading(true);

      const res = await req("select-resources-raw", { id: id });

      if (res.success) {
        setData(res.data[ 0 ]);
        setIsLoading(false);
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchResource();
  }, [ id ]);

  useEffect(() => {
    if (data.office === "TEMP") {
      setData(curr => ({ ...curr, responsable: "" }));
    }
  }, [ data.office ]);

  return <Modality show={ show } onClose={ handleClose } onSubmit={ handleSubmit } width="320px" buttons={ buttons }>

    { /* TITLE */ }
    <div className="text-xl">Resource</div>

    { /* SURNAME NAME */ }
    <FormifyField>
      <FormifyLabel>Surname Name</FormifyLabel>
      <FormifyInput required
        disabled={ isLoading || !hasAccess }
        value={ data.fullname }
        onInput={ (val) => handleDataChange("fullname", val) }
      />
    </FormifyField>

    { /* OFFICE */ }
    <FormifyField>
      <FormifyLabel>Office</FormifyLabel>
      <FormifyCombobox required
        disabled={ isLoading || !hasAccess }
        value={ data.office }
        options={ offices }
        onChange={ (val) => handleDataChange("office", val) }
      />
    </FormifyField>

    { /* responsable */ }
    <FormifyField>
      <FormifyLabel>Responsable</FormifyLabel>
      { data.office === "TM" ? (
        <FormifyInput required
          disabled={ isLoading || !hasAccess }
          value={ data.responsable }
          onInput={ (val) => handleDataChange("responsable", val) }
        />
      ) : (
        <FormifyCombobox required
          disabled={ isLoading || !hasAccess }
          value={ data.responsable }
          options={ responsables }
          onChange={ (val) => handleDataChange("responsable", val) }
        />
      ) }
    </FormifyField>

    { /* TRAVEL AVAILABILITY */ }
    <FormifyField>
      <FormifyLabel>Travel Availability</FormifyLabel>
      <FormifyCombobox required
        disabled={ isLoading || !hasAccess }
        value={ data.travelAvailability }
        options={ travelAvailabilities }
        onChange={ (val) => handleDataChange("travelAvailability", val) }
      />
    </FormifyField>

  </Modality>;
};

export default ResourceModal;