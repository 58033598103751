import Navbar, { useNavigator } from "./components/Navigator";
import Notify from "./components/Notify";
import { Transition, TransitionChild } from "@headlessui/react";
import { clsx } from "../modules/Utils";
import DashboardIcon from "../assets/icons/dashboard.png";
import DataManagementIcon from "../assets/icons/data-management.png";
import SchedulerIcon from "../assets/icons/scheduler.png";
import Auth, { useAuth } from "./components/Auth";
import { useTitleBarTracker } from "./components/TitleBarTracker";
import ContextMenu from "./components/ContextMenu";
import Dashboard from "./apps/dashboard";
import DataManagement from "./apps/data-management";
import Scheduler from "./apps/scheduler";
import Devices from "./apps/devices";

export default function App() {
	const nav = [
		{ id: 'dashboard', name: 'Dashboard', image: DashboardIcon },
		{ id: 'data-management', name: 'Management', image: DataManagementIcon },
		{ id: 'scheduler', name: 'Scheduler', image: SchedulerIcon }
	];

	const usernav = [
		{ id: 'devices', name: 'Devices', style: 'text-blue-600', onClick: () => go('profile') },
		{ id: 'logout', name: 'Log Out', style: 'text-red-600', className: "text-red-400" }
	];

	const { isOverlayVisible } = useTitleBarTracker();
	const { authStatus } = useAuth();
	const { go } = useNavigator();

	return (
		<div className="relative flex justify-center items-center w-full h-full">
			{ authStatus === "sign-in-required" && isOverlayVisible && (
				<div className="fixed w-full [-webkit-app-region:drag] h-8 top-0 left-0 z-50" />
			) }

			<Notify />

			<div className="flex justify-center items-center w-full h-full absolute">
				<Auth />
			</div>

			<Transition
				show={ authStatus === 'success' }
				as="nav"
				className="fixed top-0 left-0 z-30 flex justify-start items-center px-1 bg-[#0050a3] h-[env(titlebar-area-height,33px)] w-full duration-150"

				enter="transition duration-500"
				enterFrom="opacity-0"
				enterTo="opacity-100"

				leave="transition duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<Navbar nav={ nav } usernav={ usernav } />
			</Transition>

			<Transition
				show={ authStatus === 'success' }
				as="main"
				className={ clsx(
					"absolute flex justify-center items-center w-full h-full bg-white",
					authStatus === 'success' ? "z-20" : "z-[0!important]"
				) }
				enter="transform duration-700 z-[0!important] transition-[width,height,border-radius]"
				enterFrom="rounded-3xl w-[400px!important] h-[400px!important]"
				enterTo="rounded-0 w-full h-full"
				leave="transform duration-700 z-[0!important] transition-[width,height,border-radius]"
				leaveFrom="rounded-0 w-full h-full"
				leaveTo="rounded-3xl w-[400px!important] h-[400px!important]"
			>
				<TransitionChild
					as="div"
					className="w-full h-full"
					enter="transition duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<PageTransition page="dashboard">
						<Dashboard />
					</PageTransition>

					<PageTransition page="data-management">
						<DataManagement />
					</PageTransition>

					<PageTransition page="scheduler">
						<Scheduler />
					</PageTransition>

					<PageTransition page="profile">
						<Devices />
					</PageTransition>
				</TransitionChild>
			</Transition>

			<ContextMenu />
		</div>
	);
};

const PageTransition = ({ page, children }) => {
	const { active } = useNavigator();

	return (
		<Transition
			show={ active === page }
			as="div"
			className={ clsx("w-full h-full pt-[env(titlebar-area-height,33px)]", active !== page && "absolute") }
			enter="transition duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition duration-300 absolute"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			{ children }
		</Transition>
	);

};