import { useEffect, useState } from "react";
import { useNotifyContext } from "../../../components/Notify";
import req from "../../../../modules/Request";
import * as session from "../../../../modules/Session";

const useReasons = ({ show }) => {
  const { notify } = useNotifyContext();

  const [ reasons, setReasons ] = useState(session.getStore("reasons") || []);

  useEffect(() => {
    const fetchReasons = async () => {
      const res = await req("select-values", { type: "ASSENZE" });

      if (res.success) {
        session.addStore("reasons", res.data);
        setReasons(res.data);
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    };

    if (show) fetchReasons();
  }, [ show ]);

  return reasons;
};

export default useReasons;