import { useEffect, useState } from "react";
import { useNavigator } from "../../../components/Navigator";
import { FormifyGroup, FormifyLegend, FormifyLevel, Modality } from "../../../../components/WePack";
import * as session from "../../../../modules/Session";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import useActivities from "../hooks/useActivities";


const SkillMatrixModal = ({ show, onClose, id }) => {
  const { notify } = useNotifyContext();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState({});
  const [ resourceName, setResourceName ] = useState("...");

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: isLoading ? "Loading..." : "Update", type: "submit", styleSet: "success", disabled: isLoading }
  ];

  const activities = useActivities({ show });

  const handleClose = () => {
    if (isLoading) return;
    setData({});
    setResourceName("...");
    session.delParam("clients-form");
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const skillmatrix = Object.keys(data).reduce((acc, key) => [
        ...acc,
        [ +key, data[ key ].evaluatedLevel, data[ key ].expectedLevel ]
      ], []);
      const res = await req("sync-skillmatrix", { resource: id, skillmatrix });

      if (res.success) {
        notify("Success", "Skillmatrix has been updated", "success");
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    }

    setIsLoading(false);
  };

  const handleDataChange = (key, value) => {
    setData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("clients-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const res = await req("select-skillmatrix", { resource: id });
      const resResource = (await req("select-resources", { id }));

      if (res.success && resResource.success) {
        setResourceName(resResource.data.fullname);

        if (res.data) {
          setData(res.data.reduce((acc, item) => ({ ...acc, [ item.activity ]: { expectedLevel: +item.expectedLevel, evaluatedLevel: +item.evaluatedLevel } }), {}));
        }
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetch();
  }, [ id ]);

  return <Modality show={ show } onClose={ handleClose } onSubmit={ handleSubmit } width="920px" buttons={ buttons }>
    <FormifyLegend>Skill Matrix of <span className="font-semibold">{ resourceName }</span></FormifyLegend>

    <FormifyGroup addClasses="flex justify-end border-b py-1 space-x-[84px]">
      <span className="font-semibold ">Expected Level</span>
      <span className="font-semibold">Evaluated Level</span>
    </FormifyGroup>

    { activities.length > 0 && activities.filter(item => item.label !== 'Altro').map(({ label, value }) => (
      <FormifyGroup addClasses="justify-between py-[3px] border-b" key={ value }>
        <span>{ label }</span>
        <div className="flex space-x-8">
          <FormifyLevel
            from={ 0 }
            to={ 5 }
            step={ 1 }
            value={ data[ value ]?.expectedLevel ?? 0 }
            onChange={ (val) => handleDataChange(value, data[ value ] ? { ...data[ value ], expectedLevel: val } : { expectedLevel: val, evaluatedLevel: 0 }) }
            disabled={ isLoading }
          />

          <FormifyLevel
            from={ 0 }
            to={ 5 }
            step={ 1 }
            value={ data[ value ]?.evaluatedLevel ?? 0 }
            onChange={ (val) => handleDataChange(value, data[ value ] ? { ...data[ value ], evaluatedLevel: val } : { expectedLevel: 0, evaluatedLevel: val }) }
            disabled={ isLoading }
          />
        </div>
      </FormifyGroup>
    )) }
  </Modality>;
};

export default SkillMatrixModal;