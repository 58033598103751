import { Description, Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import React, { Fragment, useMemo, useRef, useState } from 'react';
import { ContexturePanel, ContextureButton, ContextureCategory, Flaticon, FormifyInput } from '../../../../components/WePack';
import { clsx } from '../../../../modules/Utils';

const OPTIONS_X = 128;
const OPTIONS_Y = 23;
const OPTIONS_OFFSET_Y = 23;

const FilterPanel = React.memo(({ show, onClose, onSubmit, showPer, currOffice, offices, currTravel, travels, currIntervention, interventions }) => {

  const timoutMouseEnter = useRef(null);
  const filters = {
    "resources": [
      { position: 0, label: "Office", id: 'office', currValue: currOffice, options: [ { value: undefined, label: 'All' }, ...offices ] },
      { position: 1, label: "Travel", id: 'travel', currValue: currTravel, options: [ { value: undefined, label: 'All' }, ...travels ] }
    ],
    "projects": [
      { position: 0, label: "Intervention", id: 'intervention', currValue: currIntervention, options: [ { value: undefined, label: 'All' }, ...interventions ] }
    ]
  };

  const [ showOptions, setShowOptions ] = useState({ show: false, position: { x: 0, y: 0 }, filterID: undefined });

  const selectedFilter = filters[ showPer ].find(({ id }) => id === showOptions.filterID);

  const handleClosePanels = () => {
    clearTimeout(timoutMouseEnter.current);
    timoutMouseEnter.current = null;
    setShowOptions(curr => ({ ...curr, show: false }));
    onClose();
  };

  const handleFilterPanelMouseEnter = (e, id, currValue, position) => {
    if (timoutMouseEnter.current) {
      setShowOptions(curr => ({ ...curr, show: false }));

      clearTimeout(timoutMouseEnter.current);
      timoutMouseEnter.current = null;
    }

    timoutMouseEnter.current = setTimeout(() => {
      setShowOptions({
        show: true,
        position: { x: OPTIONS_X, y: OPTIONS_Y + (OPTIONS_OFFSET_Y * position) },
        filterID: id,
        value: currValue
      })
    }, 500);
  };

  const handleFilterPanelMouseLeave = () => {
    if (timoutMouseEnter.current) {
      clearTimeout(timoutMouseEnter.current);
      timoutMouseEnter.current = null;
    }
  };

  const handleSelectFilter = (e, id, currValue, position) => {
    if (timoutMouseEnter.current) {
      setShowOptions(curr => ({ ...curr, show: false }));

      clearTimeout(timoutMouseEnter.current);
      timoutMouseEnter.current = null;
    }

    setShowOptions({
      show: true,
      position: { x: OPTIONS_X, y: OPTIONS_Y + (OPTIONS_OFFSET_Y * position) },
      filterID: id,
      value: currValue
    });
  };

  const handleCloseOptionPanel = () => {
    setShowOptions(curr => ({ ...curr, show: false }));
  }

  return (
    <>
      { /* FILTER BY */ }
      <ContexturePanel simple
        show={ show }
        onClose={ handleClosePanels }
        position={ { x: 180, y: 40 } }
      >
        <ContextureCategory label="Filter by">
          { filters[ showPer ].map(({ label, id, currValue, position }) => (
            <ContextureButton
              key={ id }
              onMouseEnter={ (e) => handleFilterPanelMouseEnter(e, id, currValue, position) }
              onMouseLeave={ handleFilterPanelMouseLeave }
              onClick={ (e) => handleSelectFilter(e, id, currValue, position) }
            >
              <span className='w-full text-start'>{ label }</span>
              <Flaticon name="angle-right" type="rr" size={ 11 } />
            </ContextureButton>
          )) }
        </ContextureCategory>

        { /* OPTIONS LIST */ }
        <ContexturePanel simple outClose
          show={ showOptions.show }
          onClose={ handleCloseOptionPanel }
          position={ showOptions.position }
        >
          <ContextureCategory label={ selectedFilter?.label }>
            { selectedFilter?.options.map(({ value, label }) => (
              <ContextureButton
                key={ value }
                onClick={ () => {
                  onSubmit({ id: selectedFilter.id, value });
                  setShowOptions(curr => ({ ...curr, show: false }));
                } }
              >
                <Flaticon name="check" type="rr" size={ 11 } className={ clsx("w-1", value !== selectedFilter.currValue && 'invisible') } />
                <span>{ label }</span>
              </ContextureButton>
            )) }
          </ContextureCategory>
        </ContexturePanel>
      </ContexturePanel>
    </>
  );
});

export default FilterPanel;