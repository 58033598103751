import { useEffect, useState } from "react";
import { useNavigator } from "../../../components/Navigator";
import { FormifyCombobox, FormifyField, FormifyInput, FormifyLabel, Modality } from "../../../../components/WePack";
import * as session from "../../../../modules/Session";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import useClients from "../hooks/useClients";
import useNations from "../hooks/useNations";
import { useAuth } from "../../../components/Auth";


const InstallationModal = ({ show, onClose, setTableData, id }) => {
  const { params } = useNavigator();
  const { notify } = useNotifyContext();
  const { userData: { permissions } } = useAuth();
  const defaultData = { description: "", city: "", nation: "", client: "" };

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(params[ "installations-form" ] ?? defaultData);
  const hasAccess = permissions.includes("SSP_AM_DATAMAN");
  const submitButtonLabel = hasAccess ? (isLoading ? "Loading..." : id ? "Edit" : "Add") : "No Access";

  const buttons = [
    { name: "Cancel", disabled: isLoading, onClick: () => handleClose() },
    { name: submitButtonLabel, type: "submit", styleSet: "success", disabled: isLoading || !hasAccess }
  ];

  const clients = useClients({ show });
  const nations = useNations({ show });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      const res = await req("update-installation", { ...data });

      if (res.success) {
        setTableData(curr => ({ ...curr, data: curr.data.map(item => item.id === res.data.id ? res.data : item) }));
        notify("Success", "Installation has been updated", "success");
        handleClose();
      } else {
        if(res.error === 'access-danied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    } else {
      const res = await req("insert-installation", { ...data });

      if (res.success) {
        setTableData(curr => curr.data ? { ...curr, data: [ ...curr.data, res.data ] } : { ...curr, data: [ res.data ] });
        notify("Success", "Installation has been added", "success");
        handleClose();
      } else {
        if(res.error === 'access-danied') {
          notify("Error", "You do not have the necessary permissions", 'error');
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }
      }
    }

    setIsLoading(false);
  };

  const handleClose = () => {
    if (isLoading) return;
    setData(defaultData);
    session.delParam("installations-form");
    onClose();
  };

  const handleDataChange = (key, value) => {
    setData(curr => {
      const newData = { ...curr, [ key ]: value };
      session.addParam("installations-form", newData);
      return newData;
    });
  };

  useEffect(() => {
    const fetchJobs = async () => {
      setIsLoading(true);

      const res = await req("select-installations-raw", { id: id });

      if (res.success) {
        setData(res.data[ 0 ]);
      } else {
        notify("Error", "Please contact the administrator", 'error');
        handleClose();
      }

      setIsLoading(false);
    };

    if (id && show) fetchJobs();
  }, [ id ]);

  return <Modality show={ show } onClose={ handleClose } onSubmit={ handleSubmit } width="320px" buttons={ buttons }>

    { /* TITLE */ }
    <div className="text-xl">New Installation</div>

    { /* DESCRIPTION */ }
    <FormifyField>
      <FormifyLabel>Name</FormifyLabel>
      <FormifyInput required
        disabled={ isLoading || !hasAccess }
        value={ data.description }
        onInput={ (val) => handleDataChange("description", val) }
      />
    </FormifyField>

    { /* CITY */ }
    <FormifyField>
      <FormifyLabel>City</FormifyLabel>
      <FormifyInput required
        disabled={ isLoading || !hasAccess }
        value={ data.city }
        onInput={ (val) => handleDataChange("city", val) }
      />
    </FormifyField>

    { /* NATION */ }
    <FormifyField>
      <FormifyLabel>Nation</FormifyLabel>
      <FormifyCombobox required
        disabled={ isLoading || !hasAccess }
        value={ data.nation }
        onChange={ (val) => handleDataChange("nation", val) }
        options={ nations }
      />
    </FormifyField>

    { /* CLIENT */ }
    <FormifyField>
      <FormifyLabel>Client</FormifyLabel>
      <FormifyCombobox required
        disabled={ isLoading || !hasAccess }
        value={ data.client }
        onChange={ (val) => handleDataChange("client", val) }
        options={ clients }
      />
    </FormifyField>

  </Modality>;
};

export default InstallationModal;