import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import * as session from "../../../../modules/Session";

const useResourcesExtend = ({ show, from, to, id }) => {
  const { notify } = useNotifyContext();

  const [ resources, setResources ] = useState(session.getStore("resources-extend") || []);

  useEffect(() => {
    const fetch = async () => {
      const res = await req('select-resources');
      const resTasks = await req('select-tasks', { showPer: "resources", from, to, exceptID: id });
      const resSkillmatrix = await req('select-skillmatrix');

      if (res.success && resSkillmatrix.success && resTasks.success) {
        if(res.data) {
          const newData = res.data.map((resource) => {
            const skillmatrix = resSkillmatrix.data?.filter((item) => item.resource === resource.id) || [];
            const project = resTasks.data?.find((item) => item.id === resource.id) || [];
            
            return {
              ...resource,
              skillmatrix,
              tasks: project.tasks
            };
          });
          session.addStore("resources-extend", newData);
          setResources(newData);
        }
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    };

    if (show && from && to) fetch();
  }, [ show, from, to ]);

  return resources;
};

export default useResourcesExtend;