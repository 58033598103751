import React, { createContext, useContext, useState } from "react";
import { ContexturePanel, ContextureButton, Flaticon } from "../../components/WePack";
import { CONTEXT_MENU_OFFSET } from "../../config/costants";

const ContextMenu = () => {
  const {
    contextMenu,
    showContextMenu,
    setShowContextMenu,
    contextMenuPosition,
    contextMenuData
  } = useContextMenu(ContextMenuContext);

  return (
    <ContexturePanel simple
      show={ showContextMenu }
      position={ { x: contextMenuPosition.x + CONTEXT_MENU_OFFSET, y: contextMenuPosition.y + CONTEXT_MENU_OFFSET } }
      onClose={ () => setShowContextMenu(false) }
    >
      { contextMenu?.map((items, j) => (
        items?.map(({ name, icon, onClick }, k) => (
          <ContextureButton
            key={ 'ContextMenuButton-' + k }
            onClick={ () => {
              if(onClick) {
                onClick(contextMenuData);
                setShowContextMenu(false);
              }
            } }
          >
            <Flaticon name={ icon.name } type={ icon.type } size={ 16 } className="w-2" />
            <span>{ name }</span>
          </ContextureButton>
        ))
      )) }
    </ContexturePanel>
  );
}

const ContextMenuContext = createContext();

const ContextMenuProvider = ({ children }) => {
  const [ contextMenu, setContextMenu ] = useState(undefined);
  const [ showContextMenu, setShowContextMenu ] = useState(false);
  const [ contextMenuPosition, setContextMenuPosition ] = useState({ x: 0, y: 0 });
  const [ contextMenuData, setContextMenuData ] = useState(undefined);

  const value = {
    setContextMenu: ({ context, position, data }) => {
      setContextMenu(context);
      setContextMenuPosition(position);
      setContextMenuData(data);
      setShowContextMenu(true);
    },
    contextMenu,
    contextMenuData,
    showContextMenu,
    setShowContextMenu,
    contextMenuPosition,
    setContextMenuPosition
  };

  return <ContextMenuContext.Provider value={ value }>
    { children }
  </ContextMenuContext.Provider>
}

const useContextMenu = () => useContext(ContextMenuContext);

export default ContextMenu;
export { ContextMenuProvider, useContextMenu };