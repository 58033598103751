import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import Moment from "../modules/Moment";
import { clsx } from "../modules/Utils";
import { Vector2D } from "../modules/Vecto";
import { useReducer } from "react";
import { Flaticon, Popcraft } from "./WePack";
import { DAY_NAMES, MONTH_NAMES } from "../config/costants";

const initialOffsetRange = { before: 100, after: 100 };

const WeGanttContext = createContext();

const useWeGantt = () => {
  return useContext(WeGanttContext);
};

function offsetRangeReducer(state, action) {
  switch (action.type) {
    case 'INCREMENT_BEFORE':
      return { ...state, before: state.before + action.payload };
    case 'INCREMENT_AFTER':
      return { ...state, after: state.after + action.payload };
    default:
      throw new Error();
  }
}

const WeGantt = ({
  cellWidth,
  cellHeight,
  fontSize,
  tasks,
  styles,
  displayHolidays,
  holidays = [],
  workingHours = 8,
  onMove,
  onResize,
  onEmptyCellClick,
  onTaskClick,
  onTaskDoubleClick,
  onTaskContextMenu,
  onTaskHover,
  onMilestoneClick,
  onMilestoneHover,
  onTimesheetClick,
  onTimesheetHover,
  TableColumns,
  TableRow,
  TimesheetCell,
  MilestoneCell,
  TaskCell,
  viewMode = 'days'
}) => {
  const scrollRef = useRef(null);
  const ganttHeaderRef = useRef(null);
  const tableHeaderRef = useRef(null);
  const firstRender = useRef(true);

  const [ offsetRange, dispatch ] = useReducer(offsetRangeReducer, initialOffsetRange);
  const [ range, setRange ] = useState(undefined);

  /** Process Data */
  const data = useMemo(() => {
    if (!tasks) return [];

    const filteredTasks = [ ...tasks ].reduce((acc, task) => {
      task.space = task.position.match(/\.+/g)?.length ?? 0;

      if (task.dates?.length > 0) {
        task.dates = task.dates.map((date) => {
          if (date.duration) {
            return {
              ...date,
              end: new Moment(date.start).end((date.duration / workingHours), holidays)
            };
          } else if (date.end) {
            return {
              ...date,
              duration: new Moment(date.start).duration(date.end, holidays) * workingHours
            };
          } else {
            console.error('Invalid date format');
            return date;
          }
        });

        task.start = task.dates[ 0 ]?.start || new Date().toISOString().split('T')[ 0 ];
        task.end = task.dates[ task.dates.length - 1 ].end;
        task.duration = task.dates.reduce((acc, date) => acc + date.duration, 0);
      } else {
        if (task.start && !task.duration && task.end) {
          task.duration = new Moment(task.start).duration(task.end, holidays) * workingHours;
        } else if (task.start && !task.end && task.duration) {
          task.end = new Moment(task.start).end(task.duration / workingHours, holidays);
        }
      }


      const childs = [ ...tasks ].filter(child => child.position.startsWith(task.position + '.'));
      if (childs.length > 0) {
        task.showChilds = task.showChilds ?? false;

        if (task.type == 'project') {
          task.start = childs.reduce((acc, child) => {
            if (child.type != 'milestone' && child.start && new Date(child.start) < new Date(acc)) {
              acc = child.start;
            }

            return acc;
          }, childs[ 0 ].start ?? new Date().toISOString().split('T')[ 0 ]);

          task.end = childs.reduce((acc, child) => {
            if (child.type != 'milestone' && child.end && new Date(child.end) > new Date(acc)) {
              acc = child.end;
            }


            return acc;
          }, childs[ 0 ].end ?? new Date().toISOString().split('T')[ 0 ]);

          task.duration = new Moment(task.start).duration(task.end, holidays) * 8;
        }
      } else {
        task.showChilds = undefined;
      }



      const parents = [ ...tasks ].filter(parent => task.position.startsWith(parent.position + '.'));
      if (parents.every(parent => parent.showChilds !== false)) {
        acc.push(task);
      }

      return acc;
    }, []);

    const sortedTasks = [ ...filteredTasks ].sort((a, b) => {
      const versionA = a.position.split('.').map(Number);
      const versionB = b.position.split('.').map(Number);

      for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
        const numA = versionA[ i ] || 0;
        const numB = versionB[ i ] || 0;

        if (numA < numB) {
          return -1;
        } else if (numA > numB) {
          return 1;
        }
      }

      return 0;
    });

    return sortedTasks;
  }, [ tasks ]);

  /** Process Range */
  useEffect(() => {
    if (!tasks) return;

    const startDates = tasks.map(task => {
      if (task.dates?.length > 0) {
        return new Date(task.dates[ 0 ].start).getTime();
      } else if (task.start) {
        return new Date(task.start).getTime();
      } else if(task.date) {
        return new Date(task.date).getTime();
      }
    }).filter(date => !isNaN(date));

    const endDates = tasks.map(task => {
      if (task.dates?.length > 0) {
        return new Date(task.dates[ task.dates.length - 1 ].end).getTime();
      } else if (task.end) {
        return new Date(task.end).getTime();
      } else if (task.duration) {
        return new Date(new Moment(task.start).end(task.duration / workingHours, holidays)).getTime();
      } else {
        return new Date(task.start).getTime();
      }
    }).filter(date => !isNaN(date));

    const minDate = new Date(Math.min(...startDates));
    const maxDate = new Date(Math.max(...endDates));

    minDate.setDate(minDate.getDate() - offsetRange.before);
    maxDate.setDate(maxDate.getDate() + offsetRange.after);

    if (range === undefined || (minDate <= new Date(range.from) && maxDate >= new Date(range.to))) {
      setRange({
        from: minDate.toISOString().split('T')[ 0 ],
        to: maxDate.toISOString().split('T')[ 0 ]
      });
    } else if (minDate <= new Date(range.from)) {
      setRange({
        ...range,
        from: minDate.toISOString().split('T')[ 0 ],
      });
    } else if (maxDate >= new Date(range.to)) {
      setRange({
        ...range,
        to: maxDate.toISOString().split('T')[ 0 ],
      });
    }
  }, [ offsetRange, data ]);

  const days = useMemo(() => {
    if (!range) return [];

    return new Moment(range.from).map(range.to, (date) => ({
      date: date.value(),
      holiday: holidays && holidays.includes(date.value()),
      displayHoliday: displayHolidays && displayHolidays.includes(date.value()),
      day: date.get('date'),
      today: date.isToday('day'),
      month: date.get('month') + 1,
      year: date.get('year'),
      dayOfWeek: date.day(),
      dayOfWeekName: date.dayname(DAY_NAMES),
      left: date.left() + 1
    }));
  }, [ range, holidays ]);

  const widthOfGantt = useMemo(() => viewMode === 'weeks' ? (
    days.filter(day => day.dayOfWeek === 0).length * cellWidth
  ) : (
    days.length * cellWidth
  ), [ viewMode, days ]);
  const heightOfHeader = useMemo(() => cellHeight * (viewMode === 'weeks' ? 2 : 4), [ viewMode, cellHeight ]);

  useEffect(() => {
    if (days?.length > 0 && firstRender.current) {
      scrollRef.current.scrollLeft = (new Moment(range.from).duration(new Date().toISOString().split('T')[ 0 ]) - 10) * cellWidth;
      firstRender.current = false;
    }
  }, [ days ]);

  const handleVerticalScroll = (e) => {
    if (ganttHeaderRef.current)
      ganttHeaderRef.current.style.top = `${e.target.scrollTop}px`;

    if (tableHeaderRef.current)
      tableHeaderRef.current.style.top = `${e.target.scrollTop}px`;
  };

  const handleHorizontalScroll = (e) => {
    const target = e.currentTarget;

    /*if (target.scrollLeft === 0 && !firstRender.current) {
      dispatch({ type: 'INCREMENT_BEFORE', payload: 25 });
      target.scrollLeft = 25 * cellWidth;
    } else if (target.scrollLeft + target.clientWidth === target.scrollWidth) {
      dispatch({ type: 'INCREMENT_AFTER', payload: 25 });
    }*/
  };

  return days && data && range && (
    <WeGanttContext.Provider value={ {
      cellWidth,
      cellHeight,
      widthOfGantt,
      heightOfHeader,
      fontSize,
      workingHours: viewMode === 'weeks' ? workingHours * 7 : workingHours,
      holidays,
      styles,
      days,
      range,
      onMove,
      onResize,
      onEmptyCellClick,
      onTaskClick,
      onTaskDoubleClick,
      onTaskContextMenu,
      onTaskHover,
      onMilestoneClick,
      onMilestoneHover,
      onTimesheetClick,
      onTimesheetHover,
      TimesheetCell,
      MilestoneCell,
      TableColumns,
      TableRow,
      TaskCell,
      viewMode
    } }>
      <div
        onScroll={ handleVerticalScroll }
        className="flex relative overflow-x-hidden overflow-y-auto h-full w-full"
      >
        <WeGanttTable tasks={ data } />

        <div
          ref={ scrollRef }
          style={ { width: widthOfGantt, maxWidth: widthOfGantt } }
          className="h-min overflow-x-auto relative overflow-y-hidden"
          onScroll={ handleHorizontalScroll }
        >
          { /* GANTT HEADER */ }
          <div
            ref={ ganttHeaderRef }
            style={ { width: widthOfGantt } }
            className="w-full absolute z-30 shadow-sm"
          >
            { viewMode === 'weeks' ? (
              <WeGanttHeaderPerWeeks />
            ) : (
              <WeGanttHeaderPerDays />
            ) }
          </div>

          { /* GANTT GRID */ }
          <div
            className="h-full select-none"
            style={ {
              width: widthOfGantt,
              marginTop: heightOfHeader
            } }
          >
            { viewMode === 'weeks' ? (
              <WeGanttGridPerWeeks data={ data } />
            ) : (
              <WeGanttGridPerDays data={ data } />
            ) }

            { /* GANTT TASKS */ }
            { data.map((task) => (
              <Task key={ task.name || task.label } task={ task } />
            )) }
          </div>
        </div>
      </div>
    </WeGanttContext.Provider>
  );
};

const Task = ({ task }) => {
  const { days, workingHours, cellWidth, cellHeight, fontSize, holidays, onMove, onResize, onTaskClick, onTaskDoubleClick,
    onTaskContextMenu, onTaskHover, onEmptyCellClick, TaskCell, range, widthOfGantt, viewMode } = useWeGantt();

  const bar = useRef(null);
  const row = useRef(null);
  const durationInDays = task.duration / workingHours % 1 == 0 ? task.duration / workingHours : Math.floor(task.duration / workingHours) + 1;

  const [ popup, setPopup ] = useState({ event: undefined, x: 0, y: 0, content: undefined, show: false });

  /** PROCESS WIDTH OF TASK */
  const width = (
    task.type === 'milestone' ? (
      cellWidth
    ) : ['task', 'project'].includes(task.type) ? (
      task.start && task.end ? (
        viewMode === 'weeks' ? (
          Math.floor(Moment(task.start).duration(task.end) / 7) * cellWidth
        ) : (
          Moment(task.start).duration(task.end) * cellWidth
        )
      ) : (
        0
      )
    ) : (
      0
    )
  );

  /** PROCESS POSITION OF TASK */
  let left = (
    task.type === 'milestone' ? (
      task.date ? (
        viewMode === 'weeks' ? (
          Math.floor(Moment(range.from).duration(task.date) / 7) * cellWidth
        ) : (
          Moment(range.from).duration(task.date) * cellWidth
        )
      ) : (
        0
      )
    ) : ['task', 'project'].includes(task.type) ? (
      task.start ? (
        viewMode === 'weeks' ? (
          Math.floor(Moment(range.from).duration(task.start) / 7) * cellWidth
        ) : (
          Moment(range.from).duration(task.start) * cellWidth
        )
      ) : (
        0
      )
    ) : (
      0
    )
  );

  /** Process Task Label */
  const taskLabel = useMemo( () => {
    if(!task.name) return "";
    if(task.type === 'milestone' && !task.date) return "";
    if(['task', 'project'].includes(task.type) && (!task.start || !task.end)) return "";

    if((task.name?.length * (fontSize / 2)) + ((fontSize / 2) * 8) > width) {
      return (
        <div className="absolute translate-x-full text-black select-none pointer-events-none -right-1">
          { TaskCell ? (
            <TaskCell task={ task } />
          ) : (
            task.name
          ) }
        </div>
      );
    } else {
      return TaskCell ? (
        <TaskCell task={ task } />
      ) : (
        task.name
      );
    }
  }, [ task, fontSize, width ]);

  /** Task Events */
  let isMove = useRef(false);
  let isResize = useRef(false);
  let clickPos = useRef(0);

  useEffect(() => {
    const handleMouseUp = (e) => {
      if (isMove.current) {
        e.preventDefault();
        isMove.current = false;
        try {

          const position = Math.floor(bar.current.offsetLeft / cellWidth);
          let newStartMoment = new Moment(range.from).add(position);

          const newStart = newStartMoment.available(holidays, "prev");
          const newEnd = new Moment(newStart).end(task.duration / workingHours, holidays);

          if (newStart !== task.start && onMove) {
            onMove({ ...task, start: newStart, end: newEnd });
          } else {
            handleTaskClick(e);
          }
        } catch (e) {
          console.error(e);
        }
        bar.current.style.left = `${left}px`;
      } else if (isResize.current) {
        e.preventDefault();
        isResize.current = false;

        try {
          const size = Math.floor(bar.current.offsetWidth / cellWidth) + 1;
          const end = new Moment(task.start).end(size);

          const newEnd = new Moment(end).available(holidays, "next");
          const newLength = new Moment(task.start).duration(newEnd, holidays);

          if (newLength !== durationInDays && onResize) {
            onResize({ ...task, duration: newLength * workingHours, end: newEnd });
            bar.current.style.width = `${newLength * cellWidth}px`;
          } else {
            bar.current.style.width = `${width}px`;
          }
        } catch (e) {
          bar.current.style.width = `${width}px`;
        }
      }
    };

    const handleMouseMove = (e) => {
      if (isMove.current) {
        const newX = e.clientX - clickPos.current;
        bar.current.style.left = `${newX}px`;
      } else if (isResize.current) {
        const newWidth = e.clientX - clickPos.current - bar.current.offsetLeft + width;
        if (newWidth > cellWidth * 3 / 4) {
          bar.current.style.width = `${newWidth}px`;
        } else {
          bar.current.style.width = `${cellWidth * 3 / 4}px`;
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [ width, left, days, task ]);

  const handleEmptyCellClick = (event) => {
    if (event.target !== event.currentTarget) return;
    const pos = Math.floor((event.clientX - event.currentTarget.getBoundingClientRect().left) / cellWidth);
    const selectedDate = days[ pos ].date;
    if (!onEmptyCellClick) return;
    onEmptyCellClick({ ...task, selectedDate, event });
  };

  const handleTaskClick = (event) => {
    if (!onTaskClick || event.button !== 0) return;
    onTaskClick(task, event);
  };

  const handleTaskDoubleClick = (event) => {
    if (!onTaskDoubleClick || event.button !== 0) return;
    onTaskDoubleClick({ task, event });
  };

  const handleTaskContextMenu = (event) => {
    if (!onTaskContextMenu) return;
    event.preventDefault();
    const position = new Vector2D().point(event, row.current).div(cellWidth).floor();
    const selectedDate = new Moment(range.from).add(position.x()).value();
    onTaskContextMenu({ task: { ...task, selectedDate }, event });
  };

  const handleTaskHover = (event) => {
    if (!onTaskHover) return;
    const content = onTaskHover({ task, event });
    if (!content) return;
    setPopup({ x: event.clientX, y: event.clientY, show: true, content });
  };

  return (
    <>
      <TaskPopup event={ popup.event } posX={ popup.x } posY={ popup.y } content={ popup.content } show={ popup.show } />

      <div
        ref={ row }
        className="relative overflow-x-hidden z-[25]"
        style={ { height: cellHeight, width: widthOfGantt } }
        onMouseDown={ handleEmptyCellClick }
      >
        { /* TIMESHEETS */ }
        { task.timesheets && task.timesheets.map((timesheet, index) => (
          <TaskTimesheet
            key={ task.id + timesheet.id }
            task={ task }
            timesheet={ timesheet }
            onClosePopup={ () => setPopup({ ...popup, show: false }) }
            onShowPopup={ ({ event, content }) => setPopup({ x: event.clientX, y: event.clientY, content, show: true }) }
          />
        )) }

        { task.dates?.length > 0 ? (
          task.dates?.map((splitTask, index) => (
            // SPLIT TASK
            <SplitTask
              key={ task.id + index }
              split={ splitTask }
              rowRef={ row }
              task={ task }
            />
          ))
        ) : task.type == 'milestone' ? (
          // MILESTONE
          <TaskMilestone
            task={ task }
            rowRef={ row }
            onClosePopup={ () => setPopup({ ...popup, show: false }) }
            onShowPopup={ ({ event, content }) => setPopup({ x: event.clientX, y: event.clientY, content, show: true }) }
          />
        ) : (
          // TASK
          <div ref={ bar } className="h-full absolute flex select-none py-0.5" style={ { top: 0, left, width } } >
            <div
              onMouseEnter={ (e) => e.currentTarget.style.filter = 'brightness(120%)' }
              onMouseLeave={ (e) => e.currentTarget.style.filter = 'brightness(100%)' }
              style={ {
                fontSize: fontSize * 0.8,
                backgroundColor: task.color || '#3b82f6',
                border: '1px solid white'
              } }
              className={ clsx(
                "w-full duration-75 z-10 flex text-white",
                task.type == 'task' && task.isDraggable && viewMode === 'days' && "cursor-pointer"
              ) }
            >
              { task.isResizable && task.type == 'task' && viewMode === 'days' && (
                <div className="w-1 select-none" />
              ) }

              <div
                className="flex-1 flex justify-center items-center select-none"
                style={ { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" } }
                onMouseDown={ (e) => {
                  if (e.button === 0) {
                    if (task.isDraggable && task.type == 'task' && viewMode === 'days' && task.start > range.from && task.end < range.to) {
                      clickPos.current = e.clientX - bar.current.offsetLeft;
                      isMove.current = true;
                    } else {
                      handleTaskClick(e);
                    }
                  }
                } }
                onContextMenu={ handleTaskContextMenu }
                onDoubleClick={ handleTaskDoubleClick }
                onMouseEnter={ handleTaskHover }
                onMouseLeave={ () => setPopup({ ...popup, show: false }) }
              >
                { taskLabel }
              </div>
              { task.isResizable && task.type == 'task' && viewMode === 'days' && (
                <div
                  className={ clsx("w-1", task.type == 'task' && task.isDraggable && "cursor-ew-resize") }
                  onMouseDown={ (e) => {
                    e.preventDefault();
                    clickPos.current = e.clientX - bar.current.offsetLeft;
                    isResize.current = true;
                  } }
                />
              ) }
            </div>
          </div>
        ) }
      </div>
    </>
  );
};

const SplitTask = ({ split, rowRef, task }) => {
  const { range, workingHours, cellWidth, days, holidays, fontSize, TaskCell, onMove, onResize, onTaskDoubleClick,
    onTaskContextMenu, viewMode } = useWeGantt();

  const bar = useRef(null);
  const barStyle = useRef(null);
  const labelRef = useRef(null);
  const splitDays = split.duration / workingHours % 1 == 0 ? split.duration / workingHours : Math.floor(split.duration / workingHours) + 1;
  const width = viewMode === 'weeks' ? (
    Math.floor(Moment(split.start).duration(split.end) / 7) > 1 ? (
      Math.floor(Moment(split.start).duration(split.end) / 7) * cellWidth
    ) : (
      cellWidth
    )
  ) : (
    Moment(split.start).duration(split.end) * cellWidth
  );
  let left = viewMode === 'weeks' ? (
    Math.floor(Moment(range.from).duration(split.start) / 7) * cellWidth
  ) : (
    Moment(range.from).duration(split.start) * cellWidth
  );



  let isMove = useRef(false);
  let isResize = useRef(false);
  let clickPos = useRef(0);

  const index = task.dates.findIndex((date) => date.id === split.id);
  const minX = useRef(0);
  const maxX = useRef(0);

  const label = TaskCell ? <TaskCell splitTask={ split } task={ task } /> : split.label;
  const labelIsOut = split.label && (split.label?.length * (fontSize / 2)) + ((fontSize / 2) * 8) > width;

  const handleMouseEnter = (event) => {
    event.currentTarget.style.filter = 'brightness(120%)';
    barStyle.current.style.zIndex = 20;
    if(labelRef.current) {
      labelRef.current.style.backgroundColor = 'white';
      labelRef.current.style.boxShadow = '0 0 4px 0 rgba(0, 0, 0, 0.4)';
      labelRef.current.style.zIndex = 20;
    }
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.filter = 'brightness(100%)';
    barStyle.current.style.zIndex = 10;
    if(labelRef.current) {
      labelRef.current.style.backgroundColor = 'transparent';
      labelRef.current.style.boxShadow = 'none';
      labelRef.current.style.zIndex = 10;
    }
  };

  const handleMove = (event) => {
    event.preventDefault();
    if (event.button !== 0 || !split.isDraggable || viewMode !== 'days') return;
    barStyle.current.style.zIndex = 20;
    clickPos.current = event.clientX - bar.current.offsetLeft;
    isMove.current = true;
  };

  const handleDoubleClick = (event) => {
    event.preventDefault();
    const parent = rowRef.current;
    const position = new Vector2D().point(event, parent).div(cellWidth).floor();
    const selDate = new Moment(range.from).add(position.x()).value();
    onTaskDoubleClick({ task: { ...task, split, selDate }, event });
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    const parent = rowRef.current;
    const position = new Vector2D().point(event, parent).div(cellWidth).floor();
    const selDate = new Moment(range.from).add(position.x()).value();
    onTaskContextMenu({ task: { ...task, split, selDate }, event });
  };

  const handleResize = (event) => {
    event.preventDefault();
    if (!split.isResizable) return;
    barStyle.current.style.zIndex = 20;
    clickPos.current = event.clientX - bar.current.offsetLeft;
    isResize.current = true;
  };

  const update = () => {
    if (index > 0) {
      const prev = task.dates[ index - 1 ];
      const prevEnd = new Moment(new Moment(prev.start).end(prev.duration / workingHours, holidays)).add(1).value();
      minX.current = (new Moment(range.from).duration(prevEnd) * cellWidth) + (cellWidth * 0.25);
    } else {
      minX.current = 0;
    }

    if (index < task.dates.length - 1) {
      const next = task.dates[ index + 1 ];
      maxX.current = (new Moment(range.from).duration(next.start) * cellWidth) - (cellWidth * 0.25);
    } else {
      maxX.current = days.length * cellWidth;
    }
  };

  useEffect(() => {
    update();
    const handleMouseUp = (e) => {
      barStyle.current.style.zIndex = 10;
      if (isMove.current) {
        e.preventDefault();
        isMove.current = false;
        try {
          const pos = new Vector2D(bar.current.offsetLeft).div(cellWidth).floor();
          let newStartMoment = new Moment(range.from).add(pos.x());

          const newStart = newStartMoment.available(holidays, "prev");
          const newEnd = new Moment(newStart).end(split.duration / workingHours, holidays);

          if (newStart !== split.start && onMove) {
            const newSplit = editSplit(workingHours, holidays, task, split, newStart, newEnd, split.duration, "move", false);
            onMove({ ...newSplit, splitID: split.id });
          } else {
            // CLICKED
          }
        } catch (e) {
          console.error(e);
        }
        bar.current.style.left = `${left}px`;
      } else if (isResize.current) {
        e.preventDefault();
        isResize.current = false;

        try {
          const size = Math.floor(bar.current.offsetWidth / cellWidth) + 1;
          const end = new Moment(split.start).end(size);

          const availableEnd = new Moment(end).available(holidays, "next");
          const newLength = new Moment(split.start).duration(availableEnd);


          if (newLength !== splitDays) {
            const newSplit = editSplit(workingHours, holidays, task, split, split.start, availableEnd, newLength * workingHours, "resize", false);
            onResize({ ...newSplit, splitID: split.id });
            bar.current.style.width = `${width}px`;
          } else {
            bar.current.style.width = `${width}px`;
          }
        } catch (e) {
          bar.current.style.width = `${width}px`;
        }
      }
    };

    const handleMouseMove = (e) => {
      if (isMove.current) {
        const newX = e.clientX - clickPos.current;
        bar.current.style.left = `${newX}px`;
        /*if (newX >= minX.current && (newX + width) <= maxX.current) {
          bar.current.style.left = `${newX}px`;
        } else if (newX < minX.current) {
          bar.current.style.left = `${minX.current}px`;
        } else {
          bar.current.style.left = `${maxX.current - width}px`;
        }*/
      } else if (isResize.current) {
        const newWidth = e.clientX - clickPos.current - bar.current.offsetLeft + width;
        if (newWidth > cellWidth * 3 / 4) {

          bar.current.style.width = `${newWidth}px`;
          /*if (newWidth < maxX.current - bar.current.offsetLeft) {
            bar.current.style.width = `${newWidth}px`;
          } else {
            bar.current.style.width = `${maxX.current - bar.current.offsetLeft}px`;
          }*/
        } else {
          bar.current.style.width = `${cellWidth * 3 / 4}px`;
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [ width, left, days, task ]);

  return (
    <div
      className="absolute flex select-none h-full py-0.5" 
      style={ { top: 0, left, width } }
      ref={ bar }
    >
      <div
        ref={ barStyle }
        style={ { backgroundColor: split.color || '#3b82f6', fontSize: fontSize * 0.8 } }
        className={ clsx(
          "border-[#00000030] border w-full duration-75 z-10 flex text-white shadow-sm",
          split.isDraggable && viewMode === 'days' && "cursor-pointer"
        ) }
        onMouseEnter={ handleMouseEnter }
        onMouseLeave={ handleMouseLeave }
      >
        { split.isResizable && viewMode === 'days' && <div className="w-2 select-none" /> }
        <div
          className="flex-1 flex justify-center items-center select-none "
          style={ { whiteSpace: "nowrap", textOverflow: "ellipsis" } }
          onMouseDown={ handleMove }
          onDoubleClick={ handleDoubleClick }
          onContextMenu={ handleContextMenu }
        >
          { labelIsOut ? (
            <div
              ref={ labelRef }
              className="absolute translate-x-full px-2 text-black select-none pointer-events-none -right-1 rounded-md"
            >
              { label }
            </div>
          ) : (
            label
          ) }
        </div>
        { split.isResizable && viewMode === 'days' && <div className="w-2 cursor-ew-resize" onMouseDown={ handleResize } /> }
      </div>
    </div>
  );
};

const TaskTimesheet = ({ timesheet, task, onClosePopup, onShowPopup }) => {
  const { range, cellHeight, cellWidth, TimesheetCell, onTimesheetClick, onTimesheetHover } = useWeGantt();

  const left = new Moment(range.from).duration(timesheet.date) * cellWidth;

  return (
    <div
      style={ { width: cellWidth, height: cellHeight, top: 0, left, backgroundColor: timesheet.color || '#d1d5db' } }
      className="h-full w-full flex flex-col justify-end items-center overflow-hidden shadow-inner absolute opacity-75 cursor-pointer"
      onMouseEnter={ (event) => {
        event.currentTarget.style.filter = 'brightness(110%)';
        if (!onTimesheetHover) return false;
        const content = onTimesheetHover({ task, timesheet, event });
        if (!content) return false;
        onShowPopup({ x: event.clientX, y: event.clientY, show: true, content });
      } }
      onMouseLeave={ (event) => {
        event.currentTarget.style.filter = 'brightness(100%)';
        onClosePopup();
      } }
      onMouseDown={ (event) => {
        if (!onTimesheetClick || event.button !== 0) return false;
        onTimesheetClick({ task, timesheet, event });
      } }
    >
      { TimesheetCell && TimesheetCell(timesheet) }
    </div>
  );
};

const TaskMilestone = ({ task, rowRef, onShowPopup, onClosePopup }) => {
  const { onMove, days, holidays, workingHours, cellWidth, cellHeight, onMilestoneHover, onMilestoneClick,
    onMilestoneContext, MilestoneCell, range, onTaskContextMenu } = useWeGantt();

  const move = useRef(false);
  const clickPos = useRef(0);
  const ref = useRef(null);
  let x = new Moment(range.from).duration(task.date) * cellWidth;

  useEffect(() => {
    const handleMouseUp = (e) => {
      if (move.current) {
        e.preventDefault();
        move.current = false;
        try {
          const pos = new Vector2D(ref.current.offsetLeft + (cellWidth / 2)).div(cellWidth).floor();
          let newStartMoment = new Moment(range.from).add(pos.x());

          const newStart = newStartMoment.available(holidays, "prev");
          const newEnd = new Moment(newStart).end(task.duration / workingHours, holidays);

          if (newStart !== task.date && onMove) {
            onMove({ ...task, date: newStart, start: newStart, end: newEnd });
          } else {
            // CLICKED
          }
        } catch (e) {
          console.error(e);
        }
        ref.current.style.left = `${x}px`;
      }
    };

    const handleMouseMove = (e) => {
      if (move.current) {
        const newX = e.clientX - clickPos.current;
        ref.current.style.left = `${newX}px`;
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [ x, days, task ]);

  return (
    <div
      ref={ ref }
      className="absolute flex select-none justify-center items-center"
      style={ { top: 0, left: `${x}px`, width: `${cellWidth}px`, height: cellHeight } }
    >
      <div
        onMouseLeave={ onClosePopup }
        onMouseEnter={ (event) => {
          if (!onMilestoneHover) return;
          const content = onMilestoneHover({ task, event });
          if (!content) return;
          onShowPopup({ event, content });
        } }
        onMouseDown={ (e) => {
          e.preventDefault();
          if (e.button === 0 && task.isDraggable) {
            move.current = true;
            clickPos.current = e.clientX - ref.current.offsetLeft;
          }
        } }
        onContextMenu={ (event) => {
          if (!onTaskContextMenu) return;
          event.preventDefault();
          const position = new Vector2D().point(event, rowRef.current).div(cellWidth).floor();
          const selDate = new Moment(range.from).add(position.x()).value();
          onTaskContextMenu({ task: { ...task, selDate }, event });
        } }
      >
        { MilestoneCell ? (
          <MilestoneCell task={ task } from={ range.from } />
        ) : (
          <div
            className={ clsx(
              "rotate-45 w-3 h-3 cursor-pointer bg-green-500 hover:bg-green-400",
              task.styles ?? "") }
          />
        ) }
      </div>
    </div>
  );
};

const TaskPopup = ({ posX, posY, content, show }) => {
  return (
    <Popcraft
      show={ show }
      className={ clsx(
        "-translate-x-1/2 min-w-[220px] bg-white rounded-sm shadow-lg border border-gray-300",
        posY > window.innerHeight * 0.75 ? "-translate-y-full" : "",
      ) }
      posX={ posX }
      posY={ posY > window.innerHeight * 0.75 ? posY - 5 : posY + 20 }
    >
      { content }
    </Popcraft>
  );
};

const WeGanttTable = ({ tasks }) => {
  const { cellHeight, fontSize, TableColumns, heightOfHeader, TableRow } = useWeGantt();

  const hasResize = useRef(false);
  const tableRef = useRef(null);
  const initialWidth = useRef(0);

  useEffect(() => {
    initialWidth.current = tableRef.current.scrollWidth;

    const handleMouseMove = (e) => {
      if (hasResize.current) {
        e.preventDefault();
        if (e.clientX - initialWidth.current < 0) {
          tableRef.current.style.width = `${e.clientX}px`;
        } else {
          tableRef.current.style.width = `${initialWidth.current}px`;
        }
      }
    };

    const handleMouseUp = (e) => {
      e.stopPropagation();
      hasResize.current = false;
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [ cellHeight, tasks ]);

  return (
    <div className="flex h-min">
      <div ref={ tableRef } className="flex w-max">
        <table className="h-full table-fixed border-spacing-0 text-nowrap">
          <thead>
            <tr style={ { height: heightOfHeader } } className="sticky top-0 z-[15] shadow-sm">
              { typeof TableColumns === 'function' ? (
                TableColumns().map((item, index) => (
                  <th key={ index } className="p-0 [&:not(:last-child)>div]:border-r">
                    <div className="flex px-1 justify-center items-center h-full bg-white border-b border-gray-200" style={ { fontSize } }>
                      { item }
                    </div>
                  </th>
                ))
              ) : (
                TableColumns.map((item, index) => (
                  <th key={ index } className="p-0">
                    <div className="flex px-1 justify-center items-center h-full bg-white border-r border-b border-gray-200" style={ { fontSize } }>
                      { item }
                    </div>
                  </th>
                ))
              ) }
            </tr>
          </thead>
          <tbody>
            { tasks.map((task) => (
              <TableRow key={ task.id } { ...task } />
            )) }
          </tbody>
        </table>
      </div>
      <div
        className="flex items-center justify-center bg-gray-100 hover:bg-gray-200 cursor-e-resize z-[30]"
        style={ { width: 10, height: (tasks.length * cellHeight) + heightOfHeader } }
        onMouseDown={ (e) => {
          e.preventDefault();
          hasResize.current = true;
        } }
      >
        <Flaticon name="menu-dots-vertical" type="br" size="xs" />
      </div>
    </div>
  );
};

const WeGanttHeaderPerDays = () => {
  const { cellWidth, cellHeight, fontSize, styles, days } = useWeGantt();

  return (
    <>
      <div
        style={ { height: `${cellHeight}px`, fontSize: fontSize } }
        className={ clsx("flex items-center overflow-hidden text-ellipsis p-0 font-semibold", styles?.border ?? "border-gray-200") }
      >
        { days.map((day, index) => {
          if (day.day === 1 || index === 0 || (day.day === 1 && day.month === days[ days.length - 1 ].month && day.year === days[ days.length - 1 ].year)) {
            const daysInMonth = Moment(day.date).monthdays();
            return (
              <div
                key={ index }
                className={ clsx(
                  "h-full border-b flex text-ellipsis text-nowrap select-none justify-center items-center",
                  "border-r bg-white border-gray-200 font-semibold hover:shadow-[inset_0_0_4px_0_#00000030]"
                ) }
                style={ {
                  minWidth: (index === 0 ? (
                    daysInMonth + 1 - day.day
                  ) : (day.month === days[ days.length - 1 ].month && day.year === days[ days.length - 1 ].year) ? (
                    days[ days.length - 1 ].day
                  ) : (
                    daysInMonth
                  )) * cellWidth
                } }
              >
                <div>
                  { Moment(day.date).monthname(MONTH_NAMES) } { day.year }
                </div>
              </div>
            );
          }
        }) }
      </div>

      <div
        style={ { height: `${cellHeight}px`, fontSize: fontSize } }
        className={ clsx("flex items-center overflow-hidden text-ellipsis border-b font-semibold", styles?.border ?? "border-gray-200") }
      >
        { days.filter((d, i) => i == 0 || d.dayOfWeek == 0).map((d, i) => {
          const last = days[ days.length - 1 ];
          const isLast = last.year == d.year && last.dayOfWeek == d.dayOfWeek;
          return (
            <div
              key={ i }
              className={ "h-full flex select-none justify-center items-center border-r bg-white border-gray-200 hover:shadow-[inset_0_0_4px_0_#00000030]" }
              style={ { minWidth: (7 - d.dayOfWeek - (isLast ? last.dayOfWeek : 0)) * cellWidth } }
            >
              <div>
                { new Moment(d.date).week() }
              </div>
            </div>
          );
        })
        }
      </div>

      <div
        style={ { height: `${cellHeight}px`, fontSize: fontSize } }
        className={ clsx("flex items-center overflow-hidden text-ellipsis border-gray-200", styles?.border ?? "") }
      >
        { days.map((day, index) => (
          <div
            className={
              clsx(
                "h-full border-b flex select-none justify-center items-center border-r text-nowrap font-semibold hover:shadow-[inset_0_0_4px_0_#00000030]",
                day.styles || "bg-white",
                styles?.border ?? "border-gray-200"
              )
            }
            style={ { minWidth: cellWidth, backgroundColor: day.today ? styles?.todayColor : day.displayHoliday ? styles?.holidayColor : "white" } }
            key={ index }
          >
            <div>
              { day.day }
            </div>
          </div>
        )) }
      </div>


      <div
        style={ { height: `${cellHeight}px`, fontSize: fontSize } }
        className={ clsx("flex items-center overflow-hidden text-ellipsis border-gray-200", styles?.border ?? "") }
      >
        { days.map((day, index) => (
          <div
            className={
              clsx(
                "h-full border-b flex select-none justify-center items-center border-r text-nowrap font-semibold hover:shadow-[inset_0_0_4px_0_#00000030]",
                day.styles || "bg-white",
                styles?.border ?? "border-gray-200"
              )
            }
            style={ { minWidth: cellWidth, backgroundColor: day.today ? styles?.todayColor : day.displayHoliday ? styles?.holidayColor : "white" } }
            key={ index }
          >
            <div>
              { Moment(day.date).dayname(DAY_NAMES).slice(0, 1) }
            </div>
          </div>
        )) }
      </div>
    </>
  );
};

const WeGanttHeaderPerWeeks = () => {
  const { cellWidth, cellHeight, fontSize, styles, days } = useWeGantt();

  return (
    <>
      <div
        style={ { height: cellHeight, fontSize: fontSize } }
        className={ clsx(
          "flex items-center overflow-hidden text-ellipsis p-0 font-semibold",
          styles?.border ?? "border-gray-200"
        ) }
      >
        { days.map((day, index) => {
          if (day.day === 1 || index === 0 || (day.day === 1 && Moment(day.date).same(days[ days.length - 1 ], 'month'))) {
            const weeksInMonth = Moment(day.date).weeksInMonth();
            const width = cellWidth * (index === 0 ? (
              weeksInMonth - Moment(day.date).week('month') + 1
            ) : Moment(day.date).same(days[ days.length - 1 ].date, 'month') ? (
              Moment(days[ days.length - 1 ].date).week('month')
            ) : (
              weeksInMonth
            ));

            return (
              <div
                key={ day.date }
                className={ clsx(
                  "h-full border-b flex text-nowrap select-none justify-center items-center overflow-x-hidden",
                  "border-r bg-white border-gray-200 font-semibold hover:shadow-[inset_0_0_4px_0_#00000030]"
                ) }
                style={ {
                  minWidth: width,
                  maxWidth: width,
                  width
                } }
              >
                <div>
                  { Moment(day.date).monthname(MONTH_NAMES).substring(0, 3) } { day.year }
                </div>
              </div>
            );
          }
        }) }
      </div>

      <div
        style={ { height: `${cellHeight}px`, fontSize: fontSize } }
        className="flex items-center overflow-hidden text-ellipsis border-gray-200"
      >
        { days.map((day) => (
          day.dayOfWeek === 0 && (
            <div
              key={ day.date }
              className={ clsx(
                "h-full border-b flex select-none justify-center items-center border-r text-nowrap",
                "font-semibold hover:shadow-[inset_0_0_4px_0_#00000030]"
              ) }
              style={ {
                minWidth: cellWidth,
                maxWidth: cellWidth,
                width: cellWidth,
                backgroundColor: Moment(day.date).isToday('week') ? styles?.todayColor ?? 'white' : 'white'
              } }
            >
              <div>
                { Moment(day.date).week('year') }
              </div>
            </div>
          )
        )) }
      </div>
    </>
  );
};

const WeGanttGridPerDays = ({ data }) => {
  const { cellWidth, cellHeight, days, styles } = useWeGantt();

  return (
    <svg className="bg-white absolute z-20" width={ cellWidth * days.length } height={ data.length * cellHeight }>
      { Array.from({ length: data.length }).map((_, index) => (
        <g key={ index }>
          { days.map((day, jndex) => {
            return (
              <g key={ jndex }>
                { (day.displayHoliday || day.today) && (
                  <rect
                    className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
                    style={ { fill: day.today ? styles?.todayColor : day.displayHoliday ? styles?.holidayColor : "white" } }
                    x={ jndex * cellWidth }
                    y={ index * cellHeight }
                    width={ cellWidth }
                    height={ cellHeight }
                  />
                ) }
                { index === 0 && (
                  <line
                    className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
                    x1={ jndex * cellWidth }
                    y1={ 0 }
                    x2={ jndex * cellWidth }
                    y2={ data.length * cellHeight }
                  />
                ) }
              </g>
            );
          }) }
          <line
            className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
            key={ index }
            x1={ 0 }
            y1={ (index + 1) * cellHeight }
            x2={ days.length * cellWidth }
            y2={ (index + 1) * cellHeight }
          />
        </g>
      )) }
    </svg>
  );
};

const WeGanttGridPerWeeks = ({ data }) => {
  const { cellWidth, cellHeight, days, styles } = useWeGantt();
  const daysWithWeeks = days.filter(day => day.dayOfWeek === 0);

  return (
    <svg className="bg-white absolute z-20" width={ cellWidth * daysWithWeeks.length } height={ data.length * cellHeight }>
      { Array.from({ length: data.length }).map((_, i) => (
        <g key={ i }>
          { daysWithWeeks.map((day, j) => {
            return (
              <g key={ day.date }>
                { (Moment(day.date).isToday('week')) && (
                  <rect
                    className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
                    style={ { fill: styles?.todayColor ?? '#AAAAAA' } }
                    x={ j * cellWidth }
                    y={ i * cellHeight }
                    width={ cellWidth }
                    height={ cellHeight }
                  />
                ) }
                { i === 0 && (
                  <line
                    className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
                    x1={ j * cellWidth }
                    y1={ 0 }
                    x2={ j * cellWidth }
                    y2={ data.length * cellHeight }
                  />
                ) }
              </g>
            );
          }) }
          <line
            className="stroke-width-1 stroke-slate-200 flex select-none justify-center items-center"
            key={ i }
            x1={ 0 }
            y1={ (i + 1) * cellHeight }
            x2={ days.length * cellWidth }
            y2={ (i + 1) * cellHeight }
          />
        </g>
      )) }
    </svg>
  );
};

const editSplit = (workingHours, holidays, task, split, newStart, newEnd, newDuration, type, join = true) => {
  const intersection = task.dates
    .filter((date) => {
      const dateStart = new Moment(date.start).add(-1).date(0);
      const dateEnd = new Moment(date.end).add(1).date(0);
      const splitStart = new Moment(newStart).date(0);
      const splitEnd = new Moment(newEnd).date(0);

      return date.id != split.id && ((splitStart >= dateStart && splitStart <= dateEnd) || (splitEnd >= dateStart && splitEnd <= dateEnd));
    })[ 0 ];

  const out = task.dates
    .filter((date) => {
      const dateStart = new Moment(date.start).add(-1).date(0);
      const dateEnd = new Moment(date.end).add(1).date(0);
      const splitStart = new Moment(newStart).date(0);
      const splitEnd = new Moment(newEnd).date(0);

      return date.id != split.id
        && (((splitStart > dateStart && splitStart < dateEnd) || (splitEnd > dateStart && splitEnd < dateEnd))
          || ((dateStart > splitStart && dateStart < splitEnd) || (dateEnd > splitStart && dateEnd < splitEnd)));
    })[ 0 ];

  if (intersection && join) {
    let duration;
    let min;
    let max;

    if (type == "move") {
      const order = task.dates.filter((date) => date.id == split.id || intersection.id == date.id)[ 0 ] == intersection ? "after" : "before";
      duration = +intersection.duration + +split.duration;
      if (order == "before") {
        max = intersection.end;
        min = new Moment(max).start(duration / workingHours, holidays);
      } else {
        min = intersection.start;
        max = new Moment(min).end(duration / workingHours, holidays);
      }
    } else if (type == "resize") {
      const order = task.dates.filter((date) => date.id == split.id || intersection.id == date.id)[ 0 ] == intersection ? "after" : "before";
      if (order == "before") {
        if (new Moment(newEnd).day() == 0) {
          duration = +newDuration + +intersection.duration - workingHours;
        } else {
          duration = +newDuration + +intersection.duration;
        };

        max = intersection.end;
        min = new Moment(max).start((duration / workingHours), holidays);
      } else {
        duration = +newDuration + +intersection.duration - workingHours;

        min = intersection.start;
        max = new Moment(min).end((duration / workingHours), holidays);
      }
    } else {
      console.error("function editSplit: Invalid type");
      return task;
    }

    const taskDates = task.dates
      .filter((date) => intersection.id != date.id)
      .map((date) => split.id == date.id ? { ...date, start: min, end: max, duration } : date)
      .sort((a, b) => new Date(a.start) - new Date(b.start));
    if (taskDates.length > 1) {
      const taskStart = taskDates.reduce((acc, date) => new Date(date.start) < new Date(acc) ? date.start : acc, taskDates[ 0 ].start);
      const taskEnd = taskDates.reduce((acc, date) => new Date(date.end) > new Date(acc) ? date.end : acc, taskDates[ taskDates.length - 1 ].end);
      const taskDuration = new Moment(taskStart).duration(taskEnd, holidays) * workingHours;
      return { ...task, start: taskStart, end: taskEnd, duration: taskDuration, dates: taskDates };
    } else {
      return { ...task, start: min, end: max, duration, dates: undefined };
    }
  } else {
    if (out) {
      return task;
    } else {
      const taskDates = task.dates
        ?.map((date) => date.id == split.id ? { ...date, start: newStart, end: newEnd, duration: newDuration } : date)
        .sort((a, b) => new Date(a.start) - new Date(b.start));
      const taskStart = taskDates[ 0 ].start;
      const taskEnd = taskDates[ taskDates.length - 1 ].end;
      const taskDuration = new Moment(taskStart).duration(taskEnd, holidays) * workingHours;
      return { ...task, start: taskStart, end: taskEnd, duration: taskDuration, dates: taskDates };
    }
  }

};

export default WeGantt;
export { editSplit };