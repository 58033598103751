
import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import * as session from "../../../../modules/Session";

const useJobs = ({ show }) => {
  const [ jobs, setJobs ] = useState(session.getStore("jobs") || []);
  const { notify } = useNotifyContext();

  useEffect(() => {
    const fetchJobs = async () => {
      const res = await req("select-jobs");

      if (res.success) {
        const newData = res.data.map(item => ({ label: item.description, value: item.id }));
        session.addStore("jobs", newData);
        setJobs(newData);
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    };

    if (show) fetchJobs();
  }, [ show ]);

  return jobs;
};

export default useJobs;